import styled from "styled-components/native";

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 27px;
  margin-right: 27px;
  background-color: transparent;
`;

export const LogoContainer = styled.View`
  background-color: white;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 15px rgba(138, 149, 158, 0.15);
  border-radius: 29px;
  width: 75%;
  height: 75%;
`;

export const Dot = styled.View`
  background-color: ${(props: { active: any }) =>
    props.active ? "#007aff" : "#86B5E3"};
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 3px;
`;

export const Circle = styled.View`
  width: ${(props: any) => props.size};
  height: ${(props: any) => props.size};
  border-radius: ${(props: any) => props.radius};
  background: #afd0f0;
  opacity: 1;
  z-index: 9;
`;
