import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  height: 45px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${Colors.mediumGray};
`;

interface SelectedTextProps {
  isPlaceholder: boolean;
}

export const SelectedText = styled.Text<SelectedTextProps>`
  flex: 1;
  font-family: "Muli_400Regular";
  font-size: 14px;
  color: ${(props: SelectedTextProps) =>
    props.isPlaceholder ? Colors.darkGray : Colors.black};
`;

export const ModalContainer = styled.View`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const ModalOverlay = styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.View`
  width: 90%;
  max-height: 80%;
  border-radius: 8px;
  background-color: ${Colors.modalBackground};
  padding: 15px;
  align-items: center;
  shadow-color: #000;
  shadow-offset: 0px 2px;
  shadow-opacity: 0.25;
  shadow-radius: 2px;
  elevation: 5;
`;

export const ModalItemList = styled.ScrollView`
  width: 100%;
`;

export const ModalItem = styled.TouchableHighlight`
  width: 100%;
  min-height: 30px;
  padding: 2px;
  justify-content: center;
  border-radius: 5px;
`;

export const ModalText = styled.Text`
  font-family: Muli_500Medium;
  font-size: 18px;
  text-align: left;
  color: ${Colors.black};
`;

export const DropdownItemList = styled.ScrollView`
  width: 100%;
  margin-top: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: ${Colors.mediumGray};
`;

export const DropdownItem = styled.TouchableHighlight`
  width: 100%;
  min-height: 30px;
  padding: 2px 10px 2px 20px;
  justify-content: center;
`;

export const DropdownText = styled.Text`
  font-family: Muli_500Medium;
  font-size: 14px;
  text-align: left;
  color: ${Colors.black};
`;
