import React, { useContext, useState } from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import AppLoading from "expo-app-loading";

import api from "../services/api";

import { AuthContext } from "../contexts/AuthContext";

import AuthRoutes from "./auth.routes";
import AppRoutes from "./app.routes";

const Routes: React.FC = () => {
  const [appIsReady, setAppIsReady] = useState(false);
  const [initialAuthRoute, setInitialAuthRoute] = useState("onboarding");
  const { authenticated, setUser } = useContext(AuthContext);

  const initApp = async () => {
    const value = await AsyncStorage.multiGet([
      "@TediApp:token",
      "@TediApp:onboardingViewed",
    ]);
    const token = value[0][1];
    const onboardingViewed = value[1][1];

    if (onboardingViewed) setInitialAuthRoute("login");

    try {
      const response = await api.post("/sessions", { token });
      
      AsyncStorage.multiSet([
        ["@TediApp:token", response.data.token],
        ["@TediApp:user", JSON.stringify(response.data.user)],
      ]);

      api.defaults.headers["Authorization"] = "Bearer " + response.data.token;

      setUser(response.data.user);
    } catch (err: any) {
      console.log(err);
      // if (err.message === "Network Error") {
      //   if (token && token.length > 0) {
      //     const value = await AsyncStorage.multiGet([
      //       "@TediApp:token",
      //       "@TediApp:user",
      //     ]);
      //     if (value && value[0][0].length > 10) {
      //       const token = value[0][1];
      //       const user = JSON.parse(value[1][1] as string);

      //       api.defaults.headers["Authorization"] = "Bearer " + token;

      //       setUser(user);
      //     }
      //   }
      // }
    }
  };

  if (!appIsReady) {
    return (
      <AppLoading
        startAsync={initApp}
        onFinish={() => setAppIsReady(true)}
        onError={console.warn}
      />
    );
  }

  return authenticated ? (
    <AppRoutes />
  ) : (
    <AuthRoutes initialRoute={initialAuthRoute} />
  );
};

export default Routes;
