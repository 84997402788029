import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${Colors.background};
`;

export const Header = styled.View`
  margin-left: 30px;
  margin-right: 30px;
`;

export const Title = styled.Text`
  font-family: Muli_700Bold;
  font-size: 32px;
  color: ${Colors.black};
`;

export const SubTitle = styled.Text`
  font-family: Muli_700Bold;
  font-size: 16px;
  color: ${Colors.darkGray};
`;

export const SearchContainer = styled.View`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
`

export const Content = styled.View`
  flex: 1;
  margin-top: 30px;
`;

export const ChildCard = styled.TouchableOpacity`
  flex-direction: row;
  height: 90px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.1)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
  border-radius: 5px;
`;

interface ChildAvatarProps {
  backgroundColor: string;
}

export const ChildAvatar = styled.View<ChildAvatarProps>`
  display: flex;
  height: 50px;
  width: 50px;
  padding-top: 10px;
  border-radius: 25px;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  shadow-color: "rgba(138, 149, 158, 0.15)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
`;

export const ChildContent = styled.View`
  margin: 0 20px;
  flex: 1;
`;

export const ChildName = styled.Text`
  font-family: Muli_700Bold;
  font-size: 20px;
  color: ${Colors.black};
`;

export const ChildMonths = styled.Text`
  font-family: Muli_700Bold;
  font-size: 14px;
  color: ${Colors.darkGray};
`;

export const ChildOptions = styled.View`
  flex-direction: row;
  align-items: center;
`;

interface ChildAnsweredProps {
  answered: boolean;
}

export const ChildAnswered = styled.View<ChildAnsweredProps>`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.answered ? Colors.main : Colors.darkGray};
`;

export const AppVersion = styled.View`
  margin-top: auto;
  margin-bottom: 10px;
`;

export const AppVersionText = styled.Text`
  font-family: Muli_400Regular;
  font-size: 9px;
  color: ${Colors.darkGray};
  text-align: right;
`;

export const Footer = styled.View`
  margin: 0 20px;
`;

export const ModalContainer = styled.View`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const ModalOverlay = styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.View`
  width: 90%;
  border-radius: 8px;
  background-color: ${Colors.modalBackground};
  padding: 30px;
  align-items: center;
  shadow-color: #000;
  shadow-offset: 0px 2px;
  shadow-opacity: 0.25;
  shadow-radius: 2px;
  elevation: 5;
`;

export const ModalTitle = styled.Text`
  margin-bottom: 20px;
  color: #eb1a20;
  font-family: Muli_700Bold;
  font-size: 20px;
`;

export const ModalText = styled.Text`
  margin-bottom: 30px;
  font-family: Muli_700Bold;
  font-size: 15px;
  color: ${Colors.black};
  text-align: justify;
`;

export const ModalButtonContainer = styled.View`
  flex-direction: row;
`;

export const ModalButton = styled.View`
  flex: 1;
`;
