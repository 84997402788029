import { User } from '../database/Models';
import api from './api';

export const resetPassword = async (status: number, email: string, code: number, password: string) => {
    try {
        const response = await api.post('/users/reset/password', {
            status,
            email,
            code,
            password,
        });
        return response;

    } catch (err) {
        return err.response;
    }
};

export const update = async (user: User) => {
    try {
        const response = await api.put('/users/update', user);
        return response;

    } catch (err) {
        return err.response;
    }
};
