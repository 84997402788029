import styled from 'styled-components/native';
import Colors from '../../utils/Colors';

interface CheckboxProps {
    borderRadius: string;
    color: string;
}

export const Wrapper = styled.View<CheckboxProps>`
    justify-content: center;
    align-items:center;
    width: 20px;
    height: 20px;
    border-width: 2.5px;
    border-style: solid;
    border-color: ${(props: CheckboxProps) => props.color}
    border-radius: ${(props: CheckboxProps) => props.borderRadius};
    `;

export const Inner = styled.View<CheckboxProps>`
    width: 10px;
    height: 10px;
    background-color: ${(props: CheckboxProps) => props.color}
    border-radius: ${(props: CheckboxProps) => props.borderRadius};
`;
