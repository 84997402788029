import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  padding: 20px;
  background-color: ${Colors.background};
`;

export const VideoCard = styled.View`
  margin-bottom: 15px;
  padding: 10px 10px 15px 10px;
  border-radius: 5px;
  background-color: ${Colors.white};
`;
  
export const VideoTitle = styled.Text`
  margin-top: 15px;
  font-family: Muli_700Bold;
  font-size: 20px;
  text-align: center;
  color: ${Colors.black};
`;
