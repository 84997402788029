import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  View,
  ScrollView,
  RefreshControl,
  TouchableWithoutFeedback,
  Modal,
  useWindowDimensions,
} from "react-native";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import ContentLoader from "react-content-loader/native";
import { Rect } from "react-native-svg";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CommonActions } from "@react-navigation/native";
import { Toast } from "native-base";

import { expo } from "../../../app.json";

import { ChildrenContext } from "../../contexts/ChildrenContext";

import Colors, { cardColors } from "../../utils/Colors";

import { Child } from "../../database/Models";

import Button from "../../components/Button";
import Input from "../../components/Input";
import ItemCard from "../../components/ItemCard";

import {
  Container,
  Header,
  SubTitle,
  Title,
  SearchContainer,
  Content,
  ChildCard,
  Footer,
  ModalContainer,
  ModalOverlay,
  ModalTitle,
  ModalText,
  ModalContent,
  ModalButtonContainer,
  ModalButton,
  AppVersion,
  AppVersionText,
} from "./styles";

interface HomeProps {
  navigation: any;
}

interface MenuProps {
  index: number;
  child: Child;
}

const Home: React.FC<HomeProps> = props => {
  const insets = useSafeAreaInsets();
  const { width } = useWindowDimensions();

  const {
    loadChildren,
    deleteChild,
    isChildrenLoading,
    childArray,
    setSelectedChild,
  } = useContext(ChildrenContext);

  const [search, setSearch] = useState("");

  const [selectedId, setSelectedId] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDelete = async (value: MenuProps | null) => {
    //   Set child to be deleted
    if (value?.child.id) setSelectedId(value.child.id);
    setIsModalVisible(!isModalVisible);

    if (isModalVisible && selectedId) {
      const response = await deleteChild(selectedId);

      if (!response || response?.status != 200) {
        Toast.show({
          title: response 
            ? response.data.error.message 
            : "Não foi possível avançar. Verifique sua conexão e tente novamente",
          duration: 1500,
          isClosable: true,
          placement: width <= 1024 ? "bottom" : "bottom-left",
          padding: 4,
          marginLeft: width <= 1024 ? 0 : 20,
          status: "error",
          variant: "left-accent",
        });
      }
    }
  };

  const onRefresh = useCallback(() => {
    loadChildren();
  }, []);

  useEffect(() => {
    const redirect = async () => {
      const fromSignup = await AsyncStorage.getItem("@TediApp:fromSignup");

      if (!fromSignup || !JSON.parse(fromSignup)) return;

      // props.navigation.dispatch(
      //   CommonActions.reset({
      //     index: 1,
      //     routes: [
      //       { name: "TabsStack" },
      //       { name: "Tutorial", params: { index: 1 } },
      //     ],
      //   })
      // );

      await AsyncStorage.removeItem("@TediApp:fromSignup");
    };

    redirect();
  }, []);

  return (
    <Container>
      <View
        style={{
          flex: 1,
          paddingTop: insets.top,
        }}>
        <Header>
          <Title>Crianças</Title>

          {isChildrenLoading ? (
            <ContentLoader
              style={{
                height: 16,
                width: "100%",
              }}
              foregroundColor={Colors.lightGray}
              backgroundColor={Colors.darkGray}>
              <Rect x="0" y="0" rx="3" ry="3" width="100" height="16" />
            </ContentLoader>
          ) : (
            <SubTitle>
              {childArray?.length === 0
                ? "Nenhum cadastro"
                : childArray?.length === 1
                ? "1 Cadastro"
                : childArray?.length + " cadastros"}
            </SubTitle>
          )}
        </Header>
        <Content>
          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={isChildrenLoading}
                onRefresh={onRefresh}
              />
            }>
            <SearchContainer>
              <Input
                placeholder="Buscar"
                keyboard="default"
                return="done"
                onChangeText={value => setSearch(value)}
                value={search}
                onSubmit={() => {}}
              />
            </SearchContainer>
            {isChildrenLoading || !childArray ? (
              <>
                <ChildCard>
                  <ContentLoader
                    style={{
                      height: 50,
                      width: "100%",
                    }}
                    backgroundColor={Colors.lightGray}
                    foregroundColor={Colors.darkGray}>
                    <Rect x="0" y="0" rx="25" ry="25" width="50" height="50" />
                    <Rect x="70" y="0" rx="3" ry="3" width="150" height="25" />
                    <Rect x="70" y="30" rx="3" ry="3" width="80" height="28" />
                  </ContentLoader>
                </ChildCard>
                <ChildCard>
                  <ContentLoader
                    style={{
                      height: 50,
                      width: "100%",
                    }}
                    backgroundColor={Colors.lightGray}
                    foregroundColor={Colors.darkGray}>
                    <Rect x="0" y="0" rx="25" ry="25" width="50" height="50" />
                    <Rect x="70" y="0" rx="3" ry="3" width="190" height="25" />
                    <Rect x="70" y="30" rx="3" ry="3" width="80" height="28" />
                  </ContentLoader>
                </ChildCard>
              </>
            ) : (
              childArray
                .filter(child => {
                  if (search.length < 3) {
                    return true
                  }

                  // Return child's with name that contains the substring
                  // Normalize and lower case both strings
                  return child.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(
                      search.normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                    )
                })
                .map((child, index) => (
                  <ItemCard
                    key={child.id}
                    item={child}
                    onPress={() => {
                      setSelectedChild(child);
                      props.navigation.navigate("FormStack", {
                        screen: "ChildDetails",
                      });
                    }}
                    onDelete={() => {
                      handleDelete({ index, child });
                    }}
                    backgroundColor={cardColors[index % 4]}
                  />
                ))
            )}
          </ScrollView>
        </Content>
        <Footer>
          <AppVersion>
            <AppVersionText>
              version: {expo.version} | ios: {expo.ios.buildNumber} | andr:{" "}
              {expo.android.versionCode}
            </AppVersionText>
          </AppVersion>
          <Button
            loading={isChildrenLoading}
            text="Cadastrar criança"
            onPress={() => props.navigation.navigate("AddChild")}
          />
        </Footer>
      </View>

      <ModalContainer>
        <Modal
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          statusBarTranslucent={true}
          onRequestClose={() => {
            setIsModalVisible(!isModalVisible);
          }}>
          <TouchableWithoutFeedback
            onPress={() => {
              setIsModalVisible(!isModalVisible);
            }}>
            <ModalOverlay>
              {/* <View style={styles.alert}> */}
              <ModalContent>
                <ModalTitle>Tem certeza?</ModalTitle>

                <ModalText>
                  Excluir essa criança vai excluir todos os formulários dela
                  também. Essa é uma ação irreversível.
                </ModalText>
                <ModalButtonContainer>
                  <ModalButton style={{ marginRight: 5, flexBasis: 'auto' }}>
                    <Button
                      text="Excluir"
                      backgroundColor="#EB1A20"
                      underlayColor="#EB1A20"
                      onPress={() => {
                        handleDelete(null);
                      }}
                    />
                  </ModalButton>
                  <ModalButton style={{ marginLeft: 5, flexBasis: 'auto' }}>
                    <Button
                      text="Cancelar"
                      onPress={() => setIsModalVisible(false)}
                    />
                  </ModalButton>
                </ModalButtonContainer>
              </ModalContent>
              {/* </View> */}
            </ModalOverlay>
          </TouchableWithoutFeedback>
        </Modal>
      </ModalContainer>
    </Container>
  );
};

export default Home;
