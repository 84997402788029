import styled from "styled-components/native";
import { RectButton } from "react-native-gesture-handler";

import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  flex-direction: column;
  background-color: white;
  align-items: center;
  padding: 30px;
  padding-bottom: 0px;
`;

export const LogoContainer = styled.View`
  height: 20%;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.View`
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
  justify-content: center;
`;

export const ButtonsContainer = styled.View`
  flex: 2;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 15px;
`;

export const TitleInput = styled.Text`
  font-family: Muli_500Medium;
  font-size: 12px;
  color: #a3a3a3;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const ForgetPass = styled(RectButton).attrs(props => {
  props;
})`
  width: 100%;
  align-items: flex-end;
  margin-top: 20px;
`;

export const ForgetText = styled.Text`
  font-family: Muli_700Bold;
  font-size: 12px;
  color: ${Colors.main};
`;
