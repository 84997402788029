import React, { useState, useContext, useRef } from "react";
import * as formService from "../services/form";

import { Child, Form } from "../database/Models";
import { ChildrenContext } from "./ChildrenContext";

import developmental_milestones from "../database/jsons/developmental_milestones.json";
import bpsc from "../database/jsons/bpsc.json";
import ppsc from "../database/jsons/ppsc.json";
import posi from "../database/jsons/posi.json";
import family_questions from "../database/jsons/family_questions.json";

interface ContextProps {
  selectedForm: Form | null;
  setSelectedForm: any;
  swycForm: any;
  setSwycForm: any;
  loadSwycForm: any;
  isFormLoading: boolean;
  createForm: any;
  updateForm: any;
  updateFormWithEntries: any;
  deleteForm: any;
}

export const FormContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const FormProvider = ({ children }: any) => {
  const { selectedChild, setSelectedChild, childArray, setChildArray } =
    useContext(ChildrenContext);

  const [selectedForm, setSelectedForm] = useState<Form>({} as Form);

  const [swycForm, setSwycForm] = useState({});

  const [isFormLoading, setIsFormLoading] = useState(false);

  async function createForm(form: Form) {
    if (!isFormLoading) {
      setIsFormLoading(true);

      const response = await formService.createForm(form);
      if (response && response.status === 201) {
        const object: Child = { ...selectedChild };
        if (!object.forms || object.forms.length === 0) object.forms = [];

        object.forms.unshift(response.data.form);
        setSelectedChild(object);

        const array = [...childArray];
        const index = array.findIndex(e => e.id === object.id);
        if (index !== -1) {
          array[index] = object;
          setChildArray(array);
        }
      }

      setIsFormLoading(false);

      return response;
    }
  }

  async function updateForm(form: Form) {
    if (!isFormLoading) {
      setIsFormLoading(true);

      const response = await formService.updateForm(form);

      if (response && response.status === 200) {
        const object: Child = { ...selectedChild };

        // Find form in child and update state
        const formIndex = object.forms?.findIndex(
          formObj => formObj.id === form.id
        );
        if (object.forms && formIndex !== undefined && formIndex !== -1) {
          object.forms[formIndex] = form;
          setSelectedChild(object);

          const array = [...childArray];
          const childIndex = array.findIndex(child => child.id === object.id);
          if (childIndex !== -1) {
            array[childIndex] = object;
            setChildArray(array);
          }
        }
      }

      setIsFormLoading(false);

      return response;
    }
  }

  async function updateFormWithEntries(form: Form, stage: string) {
    if (!isFormLoading) {
      setIsFormLoading(true);

      const response = await formService.updateFormWithEntries(form, stage);

      if (response && response.status === 200) {
        const object: Child = { ...selectedChild };

        // Find form in child and update state
        const formIndex = object.forms?.findIndex(
          formObj => formObj.id === form.id
        );

        if (object.forms && formIndex !== undefined && formIndex !== -1) {
          object.forms[formIndex] = form;
          setSelectedChild(object);

          const array = [...childArray];
          const childIndex = array.findIndex(child => child.id === object.id);
          if (childIndex !== -1) {
            array[childIndex] = object;
            setChildArray(array);
          }
        }
      }

      setIsFormLoading(false);

      return response;
    }
  }

  async function deleteForm(id: number) {
    if (!isFormLoading) {
      setIsFormLoading(true);

      const response = await formService.deleteForm(id);

      if (response && response.status === 200) {
        const object: Child = { ...selectedChild };

        // Find form in child and update state
        const formIndex = object.forms?.findIndex(form => form.id === id);
        if (formIndex !== undefined && formIndex !== -1) {
          object.forms?.splice(formIndex, 1);
          setSelectedChild(object);

          const array = [...childArray];
          const childIndex = array.findIndex(child => child.id === object.id);
          if (childIndex !== -1) {
            array[childIndex] = object;
            setChildArray(array);
          }
        }
      }

      setIsFormLoading(false);

      return response;
    }
  }

  async function loadSwycForm(stage: string) {
    switch (stage) {
      case "developmental_milestones":
        setSwycForm(developmental_milestones);
        break;
      case "bpsc":
        setSwycForm(bpsc);
        break;
      case "ppsc":
        setSwycForm(ppsc);
        break;
      case "posi":
        setSwycForm(posi);
        break;
      case "family_questions":
        setSwycForm(family_questions);
        break;
      default:
        break;
    }
  }

  return (
    <FormContext.Provider
      value={{
        selectedForm,
        setSelectedForm,
        swycForm,
        setSwycForm,
        loadSwycForm,
        isFormLoading,
        createForm,
        updateForm,
        updateFormWithEntries,
        deleteForm,
      }}>
      {children}
    </FormContext.Provider>
  );
};
