import React, { useRef, useState } from "react";
import { TextInput, View, useWindowDimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Toast } from "native-base";

import { resetPassword } from "../../services/user";

import {
  Container,
  ButtonsContainer,
  LogoContainer,
  InputContainer,
  SubTitle,
  TitleInput,
} from "./styles";

import Logo from "../../components/Logo";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Close from "../../components/Close";
import Colors from "../../utils/Colors";
import InputError from "../../components/InputError";

interface ForgotPasswordProps {
  navigation: any;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = props => {
  const passwordInputRef: React.RefObject<TextInput> = useRef(null);

  const { width, height } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState(1);
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [resetCodeError, setResetCodeError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleSubmit = async () => {
    if (status === 1) {
      if (email.length === 0) {
        setEmailError("Preencha o e-mail recuperar a senha");
        return;
      }
    } else if (status === 2) {
      setEmailError("");
      if (resetCode.length < 6) {
        setResetCodeError("Preencha o código de 6 digitos");
        return;
      }
      setResetCodeError("");
      if (password.length < 6) {
        setPasswordError("A nova senha precisa ter no mínimo 6 digitos");
        return;
      }
      setPasswordError("");
    }
    setIsLoading(true);
    const response = await resetPassword(
      status,
      email,
      parseInt(resetCode),
      password
    );

    if (response && response.status === 200) {
      Toast.show({
        title: response.data.success.message,
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "success",
        variant: "left-accent",
      });

      setStatus(2);
    } else if (!response || response?.status !== 200) {
      Toast.show({
        title: response 
          ? response.data.error.message 
          : "Não foi possível avançar. Verifique sua conexão e tente novamente",
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });
    }
    setIsLoading(false);
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.background }}>
      <Container style={{ height: "100%", paddingTop: 10 }}>
        <View style={{ width: "100%" }}>
          <Close onPress={() => props.navigation.goBack()} />
        </View>

        <LogoContainer
          style={{
            marginTop: height * 0.035,
            marginBottom: height * 0.15,
            width: width < 768 ? "70%" : "40%",
          }}>
          <Logo width="100%" height="100%" />
        </LogoContainer>
        <View style={{ flex: 1, width: width <= 1024 ? "90%" : "50%" }}>
          <InputContainer>
            {status === 1 ? (
              <>
                <SubTitle>
                  Entre com seu email abaixo para recuperar sua senha
                </SubTitle>
                <TitleInput>E-mail</TitleInput>
                <Input
                  keyboard="email-address"
                  return="done"
                  placeholder="Digite seu e-mail"
                  blurOnSubmit={true}
                  onChangeText={text => setEmail(text.trim())}
                  value={email}
                  onSubmit={() => {}}
                />
                {emailError !== "" && <InputError text={emailError} />}
              </>
            ) : (
              <>
                <SubTitle>
                  Digite o código recebido e a nova senha abaixo para atualizar
                  sua senha
                </SubTitle>
                <TitleInput>Código recebido</TitleInput>
                <Input
                  keyboard="numeric"
                  return="next"
                  placeholder="Digite o código"
                  maxLength={6}
                  onChangeText={text => setResetCode(text)}
                  value={resetCode}
                  onSubmit={() =>
                    passwordInputRef.current && passwordInputRef.current.focus()
                  }
                />
                {resetCodeError !== "" && <InputError text={resetCodeError} />}
                <TitleInput>Senha</TitleInput>
                <Input
                  inputRef={passwordInputRef}
                  secureTextEntry={true}
                  keyboard="default"
                  return="done"
                  blurOnSubmit={true}
                  onChangeText={text => setPassword(text)}
                  value={password}
                  onSubmit={() => {}}
                />
                {passwordError !== "" && <InputError text={passwordError} />}
              </>
            )}
          </InputContainer>
          <ButtonsContainer>
            <Button
              loading={isLoading}
              text={status === 1 ? "Enviar e-mail" : "Atualizar senha"}
              onPress={handleSubmit}
            />
          </ButtonsContainer>
        </View>
      </Container>
    </SafeAreaView>
  );
};
export default ForgotPassword;
