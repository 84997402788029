import React, { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Location from "expo-location";
import * as auth from "../services/auth";

import { User } from "../database/Models";

interface ContextProps {
  user: User | null;
  setUser: any;
  authenticated: boolean;
  isAuthLoading: boolean;
  signIn: any;
  signUp: any;
  signOut: any;
}

export const AuthContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);

  const [isAuthLoading, setIsAuthLoading] = useState(false);

  async function signIn(email: string, password: string) {
    if (!isAuthLoading) {
      setIsAuthLoading(true);

      const response = await auth.signIn(email, password);

      if (response.status == 200) {
        AsyncStorage.multiSet([
          ["@TediApp:token", response.data.token],
          ["@TediApp:user", JSON.stringify(response.data.user)],
        ]);

        setUser(response.data.user);
      }
      setIsAuthLoading(false);

      return response;
    }
  }

  async function signUp(user: User) {
    if (!isAuthLoading) {
      setIsAuthLoading(true);

      const { status } = await Location.getPermissionsAsync();

      if (status === "granted") {
        const location = await Location.getLastKnownPositionAsync({});

        if (location) {
          user.coordinates =
            location.coords.latitude.toString() +
            " " +
            location.coords.longitude.toString();
        }
      }

      const response = await auth.signUp(user);

      if (response && response.status == 201) {
        AsyncStorage.multiSet([
          ["@TediApp:token", response.data.token],
          ["@TediApp:user", JSON.stringify(response.data.user)],
          ["@TediApp:fromSignup", JSON.stringify(true)],
        ]);

        setUser(response.data.user);
      }
      setIsAuthLoading(false);

      return response;
    }
  }

  async function signOut() {
    AsyncStorage.multiRemove(["@TediApp:token", "@TediApp:user"]);

    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authenticated: user !== null,
        isAuthLoading,
        signIn,
        signUp,
        signOut,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
