import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Text = styled.Text`
  flex-shrink: 1;
  font-family: Muli_700Bold;
  font-size: 24px;
  color: ${Colors.ultraDarkMain};
  text-align: center;
`;

export const TextUnderline = styled.Text`
  flex-shrink: 1;
  font-family: Muli_700Bold;
  font-size: 24px;
  color: ${Colors.ultraDarkMain};
  text-align: center;
  text-decoration: underline;
`;
