import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Pressable,
  useWindowDimensions,
} from "react-native";

import { Toast } from "native-base";
import { SafeAreaView } from "react-native-safe-area-context";
import { AVPlaybackStatus, Video } from "expo-av";
import { Feather, FontAwesome5 } from "@expo/vector-icons";

import { baseURL } from "../../services/api";

import { FormContext } from "../../contexts/FormContext";
import { ChildrenContext } from "../../contexts/ChildrenContext";

import { FormEntry } from "../../database/Models";

import {
  findFormMonth,
  getMonthsDiff,
  getPassingScore,
} from "../../utils/Functions";
import Colors from "../../utils/Colors";

import Button from "../../components/Button";
import ProgressBar from "../../components/ProgressBar";
import Checkbox from "../../components/Checkbox";
import QuestionText from "../../components/QuestionText";

import {
  Container,
  MainTitle,
  DescriptionText,
  BibliographyText,
  QuestionContainer,
  VideoCard,
  ButtonsContainer,
  Separator,
  ResultTitle,
  ResultSubTitle,
  ResultCard,
  ResultCardText,
} from "./styles";

interface QuestionaryProps {
  navigation: any;
  route: any;
}

interface Answer {
  value: number;
  text: string;
}

interface UserAnswer {
  value: number;
  resultIndex: number;
}

const Questionary: React.FC<QuestionaryProps> = props => {
  const { width: screenWidth, height } = useWindowDimensions();

  const { selectedChild } = useContext(ChildrenContext);

  const {
    isFormLoading,
    selectedForm,
    setSelectedForm,
    updateFormWithEntries,
    swycForm,
  } = useContext(FormContext);

  const questions =
    swycForm.stage === "developmental_milestones"
      ? swycForm.questions.filter(
        (question: any) =>
          selectedForm &&
          question.months.includes(findFormMonth(selectedForm.months))
      )
      : swycForm.stage === "family_questions"
        ? swycForm.questions.filter(
          (question: any) =>
            selectedForm && findFormMonth(selectedForm.months) > question.months
        )
        : swycForm.questions;

  const [formEntries, setFormEntries] = useState<FormEntry[]>(
    props.route.params ? props.route.params.answers : []
  );

  // Array to follow formEntries, generate the final result and make handle back possible
  const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);
  const [lastAnswer, setLastAnswer] = useState<string[]>([]);

  const [totalAnswered, setTotalAnswered] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState([0, 0, 0, 0, 0, 0]);
  const [resultMessage, setResultMessage] = useState(
    "O desenvolvimento da criança está dentro do esperado para a idade"
  );

  // Video status for play/pause
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const videoRef = useRef<Video | null>(null);
  const [videoStatus, setVideoStatus] = useState<AVPlaybackStatus>(
    {} as AVPlaybackStatus
  );
  const [videoLoaded, setVideoLoaded] = useState(false);

  // Checkboxes for multiple answers support
  const [checkBox, setCheckBox] = useState([false, false, false, false, false]);

  const handleCheckBox = (index: number) => {
    const list = [...checkBox];

    list[index] = !list[index];

    setCheckBox(list);
  };

  // Finish the questionary
  const handleUpdate = async () => {
    const object = { ...selectedForm };

    let finalEntries = object.form_entries ? [...object.form_entries] : [];

    if (swycForm.stage === "developmental_milestones") {
      const milestonesResult = result[0];
      object.developmental_milestones_result = milestonesResult;

      let monthsDiff = getMonthsDiff(
        selectedChild.birth_date,
        selectedChild.gestational_age
      );
      if (milestonesResult < getPassingScore(monthsDiff)) {
        object.developmental_milestones_text = "A criança apresenta suspeita de atraso de desenvolvimento."
      } else {
        object.developmental_milestones_text = "O desenvolvimento da criança está dentro do esperado para a idade."
      }

    } else if (swycForm.stage === "bpsc") {
      const bpscResult = result[0] + "" + result[1] + "" + result[2];
      object.bpsc_result = bpscResult;
    } else if (swycForm.stage === "ppsc") {
      const ppscResult = result[0];
      object.ppsc_result = ppscResult;
    } else if (swycForm.stage === "posi") {
      const posiResult = result[0];
      object.posi_result = posiResult;
    } else if (swycForm.stage === "family_questions") {
      const familyQuestionsResult =
        result[0] +
        "" +
        result[1] +
        "" +
        result[2] +
        "" +
        result[3] +
        "" +
        result[4] +
        "" +
        result[5];

      object.family_questions_result = familyQuestionsResult;
    }

    // Remove other entries that were previous store in this stage to avoid
    // duplicated data
    finalEntries = finalEntries.filter(entry => entry.stage !== swycForm.stage);
    // Push new entries
    finalEntries?.push(...formEntries);

    object.form_entries = finalEntries;
    setSelectedForm(object);

    if (props.route.params && props.route.params.answers) {
      const paramResult = getParamResult();
      props.navigation.navigate("DecisionGuide", {
        stage: swycForm.stage,
        result: getResult(paramResult),
      });

      return;
    }

    const response = await updateFormWithEntries(
      {
        ...object,
        form_entries: object.form_entries.filter(
          entry => entry.stage === swycForm.stage
        ),
      },
      swycForm.stage
    );

    if (response && response.status === 200) {
      props.navigation.navigate("DecisionGuide", {
        stage: swycForm.stage,
        result: getResult(),
      });
    } else {
      Toast.show({
        title: response
          ? response.data.error.message
          : "Não foi possível avançar. Verifique sua conexão e tente novamente",
        duration: 1500,
        isClosable: true,
        placement: screenWidth <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: screenWidth <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });
    }
  };

  // Store answer and go to next question
  const handleNext = async (answer?: Answer) => {
    // Store answer values
    if (totalAnswered > 0 && answer && !showResult) {
      // Stop video
      if (videoRef.current) {
        await videoRef.current.stopAsync();
        await videoRef.current.setStatusAsync({
          positionMillis: 0,
        });
      }
      setVideoLoaded(false);
      setVideoStatus({} as AVPlaybackStatus);

      lastAnswer[totalAnswered - 1] = answer.text;
      setLastAnswer(lastAnswer);

      // Push answer into form entries array
      const entries = [...formEntries];
      entries.push({
        stage: swycForm.stage,
        question: questions[totalAnswered - 1].text,
        answer: answer.text,
        form_id: selectedForm?.id,
      });

      setFormEntries(entries);

      // Push answer into result array
      let index = 0;
      const answers = [...userAnswers];
      if (swycForm.stage === "bpsc") {
        // Bpsc result is defined in domains, or subscales
        index = questions[totalAnswered - 1].subscale;
      } else if (swycForm.stage === "family_questions") {
        // Find index for array of results.
        // In the family questions questionary each index of array
        // represents a question or group of questions
        if (totalAnswered === 1) index = 0;
        else if (totalAnswered > 1 && totalAnswered < 5) index = 1;
        else if (totalAnswered === 5) index = 2;
        else if (totalAnswered === 6 || totalAnswered === 7) index = 3;
        else if (totalAnswered === 8 || totalAnswered === 9) index = 4;
        else if (totalAnswered === 10) index = 5;
      }

      answers.push({
        resultIndex: index,
        value: answer.value,
      });

      setUserAnswers(answers);
    }

    // Increment steps
    if (totalAnswered < questions.length) setTotalAnswered(totalAnswered + 1);
  };

  // Handle header backbutton on press
  const handleBack = () => {
    // Go back if user is viewing answers but did not respond
    if (
      totalAnswered === 0 ||
      (props.route.params && props.route.params.answers)
    ) {
      props.navigation.goBack();
      return;
    }

    if (totalAnswered > 1) {
      // Remove last element of array of entries when returning
      const entries = [...formEntries];
      entries.pop();
      setFormEntries(entries);

      // Remove last element of array of user answers when returning
      const answers = [...userAnswers];
      answers.pop();
      setUserAnswers(answers);
    }

    if (showResult) setShowResult(false);
    else setTotalAnswered(totalAnswered - 1);
  };

  // Get the result using answers stored
  const getResult = (result?: number[]) => {
    // Compute result
    const resultObject = result ?? [0, 0, 0, 0, 0, 0];
    if (result === null || result === undefined) {
      userAnswers.forEach((ans: UserAnswer) => {
        resultObject[ans.resultIndex] += ans.value;
      });
    }
    setResult(resultObject);

    const stage = swycForm.stage;

    let returnValue: number | string = 0;

    switch (stage) {
      case "developmental_milestones":
        let monthsDiff = getMonthsDiff(
          selectedChild.birth_date,
          selectedChild.gestational_age
        );
        let passingScore = getPassingScore(monthsDiff);

        if (resultObject[0] < passingScore) {
          setResultMessage(
            "A criança necessita de reavaliação por um profissional da saúde."
          );
          returnValue = 1
        } else {
          setResultMessage(
            "O desenvolvimento da criança está dentro do esperado para a idade."
          );
        }

        break;
      case "bpsc":
        // Bpsc uses subscales to generate the result
        if (
          resultObject[0] >= 3 ||
          resultObject[1] >= 3 ||
          resultObject[2] >= 3
        ) {
          setResultMessage(
            "A criança necessita de reavaliação por um profissional da saúde."
          );
          returnValue = 1;
        } else {
          setResultMessage(
            "O comportamento da criança está dentro do esperado para idade."
          );
        }

        break;
      case "ppsc":
        if (resultObject[0] >= 9) {
          setResultMessage(
            "A criança necessita de reavaliação por um profissional da saúde."
          );
          returnValue = 1;
        } else {
          setResultMessage(
            "O comportamento da criança está dentro do esperado para idade."
          );
        }

        break;
      case "posi":
        if (resultObject[0] >= 3) {
          setResultMessage(
            "A criança necessita de reavaliação por um profissional da saúde."
          );
          returnValue = 1;
        } else {
          setResultMessage(
            "A interação social da criança parece atender as expectativas para a idade."
          );
        }

        break;
      case "family_questions":
        // Create variable to avoid messing setResult
        const manipulable = [...resultObject];

        manipulable[3] = manipulable[3] >= 3 ? 3 : 0;
        manipulable[4] = manipulable[4] >= 2 ? 2 : 0;
        manipulable[5] = manipulable[5] < 3 ? 1 : 0;

        returnValue =
          manipulable[0] +
          "" +
          manipulable[1] +
          "" +
          manipulable[2] +
          "" +
          manipulable[3] +
          "" +
          manipulable[4];

        let message = "";
        if (parseInt(returnValue) > 0) {
          message =
            "A criança está exposta a fatores de risco no ambiente familiar.";
          if (manipulable[5] > 0) {
            message +=
              "\nA criança necessita de mais estímulos, como a leitura de livros de infantis.";
          }
        } else {
          message =
            "Não foram identificados fatores de risco no ambiente familiar.";
          if (manipulable[5] > 0) {
            message =
              "A criança necessita de mais estímulos, como a leitura de livros de infantis.";
          }
        }

        setResultMessage(message);
        returnValue += "" + manipulable[5];
        break;
      default:
        break;
    }

    return returnValue;
  };

  const getParamResult = () => {
    let result = [0, 0, 0, 0, 0, 0];
    switch (swycForm.stage) {
      case "developmental_milestones":
        if (selectedForm?.developmental_milestones_result)
          result[0] = selectedForm.developmental_milestones_result;
        break;
      case "bpsc":
        if (selectedForm?.bpsc_result) {
          result[0] = parseInt(selectedForm.bpsc_result[0]);
          result[1] = parseInt(selectedForm.bpsc_result[1]);
          result[2] = parseInt(selectedForm.bpsc_result[2]);
        }
        break;
      case "ppsc":
        if (selectedForm?.ppsc_result) result[0] = selectedForm.ppsc_result;
        break;
      case "posi":
        if (selectedForm?.posi_result) result[0] = selectedForm.posi_result;
        break;
      case "family_questions":
        if (selectedForm?.family_questions_result) {
          result[0] = parseInt(selectedForm.family_questions_result[0]);
          result[1] = parseInt(selectedForm.family_questions_result[1]);
          result[2] = parseInt(selectedForm.family_questions_result[2]);
          result[3] = parseInt(selectedForm.family_questions_result[3]);
          result[4] = parseInt(selectedForm.family_questions_result[4]);
          result[5] = parseInt(selectedForm.family_questions_result[5]);
        }
        break;

      default:
        break;
    }

    return result;
  };

  useEffect(() => {
    const paramResult = getParamResult();
    getResult(paramResult);

    props.navigation.setOptions({
      title: swycForm.header,
      headerTitleContainerStyle: {
        left: 50,
      },
      headerLeft: () => (
        <TouchableOpacity onPress={handleBack}>
          <Feather name="chevron-left" size={24} color={Colors.white} />
        </TouchableOpacity>
      ),
    });
  }, []);

  useEffect(() => {
    if (
      questions &&
      userAnswers.length === questions.length &&
      formEntries.length === questions.length
    ) {
      getResult();
      setShowResult(true);
    }
  }, [userAnswers, formEntries]);

  useLayoutEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={handleBack}>
          <Feather name="chevron-left" size={24} color={Colors.white} />
        </TouchableOpacity>
      ),
    });
  }, [totalAnswered, showResult]);

  var image = require("../../../assets/img/developmental_milestones.png");
  switch (swycForm.stage) {
    case "developmental_milestones":
      image = require("../../../assets/img/developmental_milestones.png");
      break;
    case "bpsc":
      image = require("../../../assets/img/bpsc.png");
      break;
    case "ppsc":
      image = require("../../../assets/img/ppsc.png");
      break;
    case "posi":
      image = require("../../../assets/img/posi.png");
      break;
    case "family_questions":
      image = require("../../../assets/img/family_questions.png");
      break;
    default:
      break;
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: 10,
        backgroundColor:
          totalAnswered === 0 && props.route.params?.answers === undefined
            ? Colors.white
            : Colors.background,
      }}>
      {/* Title and description text of questionary */}
      {totalAnswered === 0 && props.route.params?.answers === undefined && (
        <Container style={{ backgroundColor: Colors.white, height: "100%" }}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Image
              style={{
                flex: 1,
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
              resizeMode="contain"
              source={image}
            />
            <MainTitle>{swycForm.title}</MainTitle>
            <DescriptionText>{swycForm.desc}</DescriptionText>
          </ScrollView>

          <ButtonsContainer>
            <BibliographyText>{swycForm.bibliography ?? ""}</BibliographyText>
            <Button text="Avançar" onPress={() => handleNext()} />
          </ButtonsContainer>
        </Container>
      )}
      {/* Questions */}
      {totalAnswered > 0 &&
        !showResult &&
        props.route.params?.answers === undefined && (
          <Container>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              <ProgressBar
                percentage={(totalAnswered / questions.length) * 100}
              />
              {questions[totalAnswered - 1].video && (
                <VideoCard>
                  <View
                    onLayout={e => {
                      const newWidth = e.nativeEvent.layout.width;
                      const newHeight = e.nativeEvent.layout.height;

                      setVideoWidth(newWidth);
                      setVideoHeight(newHeight);
                    }}>
                    <TouchableWithoutFeedback
                      onPress={async () => {
                        // @ts-ignore
                        if (videoRef.current)
                          await videoRef.current.pauseAsync();
                      }}>
                      <Video
                        ref={component => (videoRef.current = component)}
                        source={{
                          uri:
                            baseURL +
                            "/video/" +
                            questions[totalAnswered - 1].video,
                        }}
                        style={{
                          aspectRatio: 16 / 9,
                        }}
                        useNativeControls
                        usePoster
                        posterStyle={{
                          resizeMode: "stretch",
                        }}
                        resizeMode="stretch"
                        onLoadStart={() => {
                          setVideoLoaded(false);
                        }}
                        onLoad={() => setVideoLoaded(true)}
                        onPlaybackStatusUpdate={status =>
                          setVideoStatus(status)
                        }
                      />
                    </TouchableWithoutFeedback>
                  </View>
                  {videoLoaded &&
                    videoStatus.isLoaded &&
                    !videoStatus.isPlaying &&
                    !videoStatus.isBuffering &&
                    videoRef.current && (
                      <Pressable
                        onPress={async () => {
                          if (videoRef.current) {
                            await videoRef.current.replayAsync({
                              positionMillis: 0,
                              shouldPlay: true,
                            });
                          }
                        }}
                        style={{
                          aspectRatio: 16 / 9,
                          width: videoWidth,
                          height: videoHeight,
                          position: "absolute",
                          top: 10,
                          left: 10,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}>
                        <FontAwesome5
                          name="play-circle"
                          size={57}
                          color={Colors.white}
                        />
                      </Pressable>
                    )}
                  {(!videoLoaded ||
                    videoStatus.isLoaded !== true ||
                    (videoStatus.isBuffering && !videoStatus.isPlaying)) && (
                      <View
                        style={{
                          aspectRatio: 16 / 9,
                          width: videoWidth,
                          height: videoHeight,
                          position: "absolute",
                          top: 10,
                          left: 10,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          zIndex: 1000,
                        }}>
                        <ActivityIndicator color={Colors.white} size="large" />
                      </View>
                    )}
                </VideoCard>
              )}
              <QuestionContainer style={{ paddingVertical: 30, paddingHorizontal: 10 }}>
                <QuestionText
                  text={questions[totalAnswered - 1].text}
                />
              </QuestionContainer>
              <ButtonsContainer>
                {/* Posi and family questions have different json structures, on those, the answers 
            are inside questions object */}
                {swycForm.stage !== "posi" &&
                  swycForm.stage !== "family_questions" ? (
                  swycForm.answers.map((answer: Answer, index: number) => (
                    <View key={index} style={{ marginTop: 10 }}>
                      <Button
                        text={answer.text}
                        onPress={() => handleNext(answer)}
                        backgroundColor={
                          lastAnswer[totalAnswered - 1] &&
                            lastAnswer[totalAnswered - 1].includes(answer.text)
                            ? "#32a852"
                            : undefined
                        }
                        underlayColor={
                          lastAnswer[totalAnswered - 1] &&
                            lastAnswer[totalAnswered - 1].includes(answer.text)
                            ? "#32a852"
                            : undefined
                        }
                      />
                      {/* <Separator /> */}
                    </View>
                  ))
                ) : (
                  <>
                    {questions[totalAnswered - 1].multiple && (
                      <View style={{ marginTop: 7 }}>
                        <Text
                          style={{
                            color: Colors.darkGray,
                            fontSize: 13,
                            fontFamily: "Muli_400Regular",
                            textAlign: "center",
                          }}>
                          Você pode marcar mais de uma opção
                        </Text>
                      </View>
                    )}
                    {questions[totalAnswered - 1].answers.map(
                      (answer: Answer, index: number) => (
                        <View key={index} style={{ marginTop: 10 }}>
                          <Button
                            text={answer.text}
                            onPress={() => {
                              if (questions[totalAnswered - 1].multiple)
                                handleCheckBox(index);
                              else handleNext(answer);
                            }}
                            backgroundColor={
                              lastAnswer[totalAnswered - 1] &&
                                lastAnswer[totalAnswered - 1].includes(
                                  answer.text
                                )
                                ? "#32a852"
                                : undefined
                            }
                            underlayColor={
                              lastAnswer[totalAnswered - 1] &&
                                lastAnswer[totalAnswered - 1].includes(
                                  answer.text
                                )
                                ? "#32a852"
                                : undefined
                            }>
                            {/* Checkbox for multiple possibilites */}
                            {questions[totalAnswered - 1].multiple && (
                              <Checkbox
                                wrapperRadius="4px"
                                innerRadius="2px"
                                color={Colors.white}
                                selected={checkBox[index]}
                              />
                            )}
                          </Button>
                          {/* <Separator /> */}
                        </View>
                      )
                    )}
                    {/* Next button for when user can select multiple answers */}
                    <Separator />
                    {questions[totalAnswered - 1].multiple && (
                      <Button
                        text="Avançar"
                        onPress={() => {
                          let answers = questions[
                            totalAnswered - 1
                          ].answers.map((answer: Answer, index: number) => {
                            if (checkBox[index]) return answer;
                          });
                          answers = answers.filter((answer: Answer) => answer);
                          if (answers.length === 0) {
                            Toast.show({
                              title: "Marque uma opção",
                              duration: 1500,
                              isClosable: true,
                              placement:
                                screenWidth <= 1024 ? "bottom" : "bottom-left",
                              padding: 4,
                              marginLeft: screenWidth <= 1024 ? 0 : 20,
                              status: "error",
                              variant: "left-accent",
                            });

                            return;
                          }

                          let answer: Answer = {
                            value: 0,
                            text: "",
                          };
                          answers.forEach((ans: Answer, index: number) => {
                            if (index < answers.length - 1) {
                              let text = ans.text + ", ";
                              answer.text += text;
                            } else {
                              answer.text += ans.text;
                            }
                            answer.value += ans.value;
                          });
                          if (answer.value > 1) answer.value = 1;

                          setCheckBox([false, false, false, false, false]);

                          handleNext(answer);
                        }}
                      />
                    )}
                  </>
                )}
              </ButtonsContainer>
            </ScrollView>
          </Container>
        )}
      {/* Results, from props or current answers */}
      {(props.route.params?.answers !== undefined || showResult) && (
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Container>
            <ResultTitle>{resultMessage}</ResultTitle>
            {swycForm.stage !== "family_questions" && (
              <>
                <ResultSubTitle>Pontuação:</ResultSubTitle>
                <ResultCard>
                  <ResultCardText>
                    {swycForm.stage === "bpsc"
                      ? "Inflexibilidade: " +
                      result[0] +
                      "\nIrritabilidade: " +
                      result[1] +
                      "\nDificuldades com a rotina: " +
                      result[2]
                      : result[0].toString()}
                  </ResultCardText>
                </ResultCard>
              </>
            )}

            <ResultSubTitle>Histórico de respostas:</ResultSubTitle>
            {formEntries.map((entry, index) => (
              <ResultCard key={index}>
                <QuestionText
                  text={entry.question + " - " + entry.answer}
                  style={{
                    fontSize: 16,
                    textAlign: "left",
                    color: Colors.darkGray,
                  }}
                />
              </ResultCard>
            ))}
            <View style={{ marginVertical: 10 }}>
              <Text
                style={{
                  color: Colors.black,
                  fontSize: 17,
                  fontFamily: "Muli_500Medium",
                  textAlign: "center",
                }}>
                Confira as respostas antes de avançar
              </Text>
            </View>
            <ButtonsContainer>
              <Button
                text="Avançar"
                onPress={handleUpdate}
                loading={isFormLoading}
              />
              <Separator />
            </ButtonsContainer>
          </Container>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default Questionary;
