import styled from 'styled-components/native';

export const Container = styled.View`
    flex: 1;
    margin-top: 3px;
`;

export const Message = styled.Text`
    color: #FF001B;
    font-size: 10px;
`;
