import React, { useState, useRef } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";

import * as Clipboard from "expo-clipboard";
import * as ScreenOrientation from "expo-screen-orientation";
import { activateKeepAwake, deactivateKeepAwake } from "expo-keep-awake";
import { Video, AVPlaybackStatus, VideoFullscreenUpdateEvent } from "expo-av";
import { FontAwesome5 } from "@expo/vector-icons";
import { Toast } from "native-base";

import { baseURL } from "../../services/api";
import Colors from "../../utils/Colors";

import { Container, VideoCard, VideoTitle } from "./styles";

interface VideoType {
  title: string;
  uri: string;
  youtube_url: string;
  youtube_title: string;
  ref: React.MutableRefObject<null>;
  status: AVPlaybackStatus;
}

const Videos: React.FC = () => {
  const onFullscreenUpdate = async ({
    fullscreenUpdate,
  }: VideoFullscreenUpdateEvent) => {
    switch (fullscreenUpdate) {
      case Video.FULLSCREEN_UPDATE_PLAYER_DID_PRESENT:
        await ScreenOrientation.unlockAsync(); // only on Android required
        break;
      case Video.FULLSCREEN_UPDATE_PLAYER_WILL_DISMISS:
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.PORTRAIT
        ); // only on Android required
        break;
    }
  };
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const { width: screenWidth } = useWindowDimensions();

  const [status, setStatus] = useState<AVPlaybackStatus[]>([
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
    {} as AVPlaybackStatus,
  ]);

  const handleStatus = (index: number, value: any) => {
    const list = [...status];

    if (value.isPlaying) {
      activateKeepAwake();
    } else {
      deactivateKeepAwake();
    }

    list[index] = value;

    setStatus(list);
  };

  const videoList: VideoType[] = [
    {
      title: "Cuidando e Aprendendo",
      uri: "video-cuidando-aprendendo",
      youtube_url: "https://www.youtube.com/watch?v=GWuMbOcwTg0",
      youtube_title:
        "Confira o vídeo Cuidando e Aprendendo do TEDI",
      ref: useRef(null),
      status: status[0],
    },
    {
      title: "Cuidados responsivos",
      uri: "video-cuidados-responsivos",
      youtube_url: "https://www.youtube.com/watch?v=BhdoGjHGLSg",
      youtube_title: "Confira o vídeo de estímulos do TEDI sobre cuidados responsivos",
      ref: useRef(null),
      status: status[1],
    },
    {
      title: "Uso de telas por crianças",
      uri: "video-0-5-years",
      youtube_url: "https://www.youtube.com/watch?v=4nn5b8J9egI",
      youtube_title: "Confira o vídeo de estímulos do TEDI sobre uso de telas por crianças",
      ref: useRef(null),
      status: status[2],
    },
    {
      title: "Estímulos para crianças de 1 a 3 meses",
      uri: "video-1-3-months",
      youtube_url: "https://www.youtube.com/watch?v=vQvxx2qY_Hk",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 1 a 3 meses",
      ref: useRef(null),
      status: status[3],
    },
    {
      title: "Estímulos para crianças de 4 a 5 meses",
      uri: "video-4-5-months",
      youtube_url: "https://www.youtube.com/watch?v=dtLdPphblqY",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 4 a 5 meses",
      ref: useRef(null),
      status: status[4],
    },
    {
      title: "Estímulos para crianças de 6 a 8 meses",
      uri: "video-6-8-months",
      youtube_url: "https://www.youtube.com/watch?v=OFvNkLZS-yI",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 6 a 8 meses",
      ref: useRef(null),
      status: status[5],
    },
    {
      title: "Estímulos para crianças de 9 a 11 meses",
      uri: "video-9-11-months",
      youtube_url: "https://www.youtube.com/watch?v=_cq_kPDawNI",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 9 a 11 meses",
      ref: useRef(null),
      status: status[6],
    },
    {
      title: "Estímulos para crianças de 12 a 17 meses",
      uri: "video-12-17-months",
      youtube_url: "https://www.youtube.com/watch?v=Gy9-Nek97gA",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 12 a 17 meses",
      ref: useRef(null),
      status: status[7],
    },
    {
      title: "Estímulos para crianças de 18 a 22 meses",
      uri: "video-18-22-months",
      youtube_url: "https://www.youtube.com/watch?v=bbhzC4lPbfQ",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 18 a 22 meses",
      ref: useRef(null),
      status: status[8],
    },
    {
      title: "Estímulos para crianças de 23 a 28 meses",
      uri: "video-23-28-months",
      youtube_url: "https://www.youtube.com/watch?v=vnDTWq3YzIo",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 23 a 28 meses",
      ref: useRef(null),
      status: status[9],
    },
    {
      title: "Estímulos para crianças de 29 a 34 meses",
      uri: "video-29-34-months",
      youtube_url: "https://www.youtube.com/watch?v=nPAeQsntfyo",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 29 a 34 meses",
      ref: useRef(null),
      status: status[10],
    },
    {
      title: "Estímulos para crianças de 35 a 40 meses",
      uri: "video-35-40-months",
      youtube_url: "https://www.youtube.com/watch?v=5BSrYp53f8Q",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 35 a 40 meses",
      ref: useRef(null),
      status: status[11],
    },
    {
      title: "Estímulos para crianças de 41 a 46 meses",
      uri: "video-41-46-months",
      youtube_url: "https://www.youtube.com/watch?v=6Lgdx6GaeKw",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 41 a 46 meses",
      ref: useRef(null),
      status: status[12],
    },
    {
      title: "Estímulos para crianças de 47 a 52 meses",
      uri: "video-47-52-months",
      youtube_url: "https://www.youtube.com/watch?v=UVCxBm_tuPs",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 47 a 52 meses",
      ref: useRef(null),
      status: status[13],
    },
    {
      title: "Estímulos para crianças de 53 a 58 meses",
      uri: "video-53-58-months",
      youtube_url: "https://www.youtube.com/watch?v=-rBQ6EfqrWc",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 53 a 58 meses",
      ref: useRef(null),
      status: status[14],
    },
    {
      title: "Estímulos para crianças de 59 a 65 meses",
      uri: "video-59-65-months",
      youtube_url: "https://www.youtube.com/watch?v=swskQT-Jnxo",
      youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 59 a 65 meses",
      ref: useRef(null),
      status: status[15],
    },
  ];

  return (
    <Container>
      <ScrollView showsVerticalScrollIndicator={false}>
        {videoList.map((v: any, index) => (
          <VideoCard key={index}>
            <View
              onLayout={e => {
                const newWidth = e.nativeEvent.layout.width;
                const newHeight = e.nativeEvent.layout.height;

                setWidth(newWidth);
                setHeight(newHeight);
              }}>
              <Video
                ref={v.ref}
                source={{
                  uri: baseURL + "/video/" + v.uri,
                }}
                style={{
                  aspectRatio: 16 / 9,
                }}
                usePoster
                posterSource={{
                  uri: baseURL + "/video/thumb/" + v.uri,
                  cache: "reload",
                }}
                posterStyle={{
                  resizeMode: "stretch",
                }}
                useNativeControls
                resizeMode="contain"
                onFullscreenUpdate={onFullscreenUpdate}
                onPlaybackStatusUpdate={status => handleStatus(index, status)}
              />
            </View>
            {v.status.isLoaded && !v.status.isPlaying && v.ref.current && (
              <View
                style={{
                  position: "absolute",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}>
                <TouchableOpacity
                  onPress={() => {
                    // @ts-ignore
                    if (v.ref.current) {
                      if (
                        v.status.positionMillis < v.status.durationMillis &&
                        v.status.positionMillis > 0
                      )
                        v.ref.current.playAsync();
                      else
                        v.ref.current.replayAsync({
                          positionMillis: 0,
                          shouldPlay: true,
                        });
                    }
                    if (v.status.isLoaded && v.status.isPlaying) {
                      const current = { ...v.status };
                      current.isPlaying = true;
                      handleStatus(index, current);
                    }
                  }}
                  style={{
                    width,
                    height,
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <FontAwesome5
                    name="play-circle"
                    size={57}
                    color={Colors.white}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    Clipboard.default.setString(
                      `${v.youtube_title} : \n${v.youtube_url}`
                    );

                    Toast.show({
                      title: "Link copiado para a área de transferência",
                      duration: 1500,
                      isClosable: true,
                      placement: screenWidth <= 1024 ? "bottom" : "bottom-left",
                      padding: 4,
                      marginLeft: screenWidth <= 1024 ? 0 : 20,
                    });
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}>
                  <FontAwesome5
                    name="share-alt"
                    size={24}
                    color={Colors.white}
                  />
                </TouchableOpacity>
              </View>
            )}
            {v.status.isLoaded !== true && (
              <View
                style={{
                  width: width,
                  height: height,
                  position: "absolute",
                  top: 10,
                  left: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1000,
                }}>
                <ActivityIndicator color={Colors.white} size="large" />
              </View>
            )}
            <VideoTitle>{v.title}</VideoTitle>
          </VideoCard>
        ))}
      </ScrollView>
    </Container>
  );
};

export default Videos;
