import React from "react";
import { View, Image, StyleSheet, useWindowDimensions } from "react-native";

import { SafeAreaView } from "react-native-safe-area-context";
import Swiper from "react-native-web-swiper";

import Close from "../../components/Close";
import Colors from "../../utils/Colors";

import { Container, TextContainer, Title, Description } from "./styles";

interface CreditsProps {
  navigation: any;
}

const Credits: React.FC<CreditsProps> = props => {
  const { width } = useWindowDimensions();

  const styles = StyleSheet.create({
    slide: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
  });

  return (
    <SafeAreaView style={{ backgroundColor: Colors.white, flex: 1 }}>
      <Container>
        <View style={{ width: "100%" }}>
          <Close onPress={() => props.navigation.goBack()} />
        </View>
        <View style={{ flex: 1 }}>
          <View style={{ flex: 1 }}>
            <Swiper
              loop
              timeout={2}
              minDistanceForAction={0.1}
              controlsProps={{
                prevPos: false,
                nextPos: false,
                dotsPos: false,
              }}>
              {/* Slide 1 */}
              <View style={styles.slide}>
                <Image
                  source={require("../../../assets/img/principal_ufmg.png")}
                  style={{
                    width: width <= 768 ? 350 : 1.5 * 350,
                    height: "100%",
                  }}
                  resizeMode="contain"
                />
              </View>
              {/* Slide 2 */}
              <View style={styles.slide}>
                <Image
                  source={require("../../../assets/img/principal_ufsc.png")}
                  style={{
                    width: width <= 768 ? 200 : 1.5 * 200,
                    height: "100%",
                  }}
                  resizeMode="contain"
                />
              </View>
              {/* Slide 3 */}
              <View style={styles.slide}>
                <Image
                  source={require("../../../assets/img/principal_fmcsv.png")}
                  style={{
                    width: width <= 768 ? 300 : 1.5 * 300,
                    height: "100%",
                  }}
                  resizeMode="contain"
                />
              </View>
              {/* Slide 4 */}
              <View style={styles.slide}>
                <Image
                  source={require("../../../assets/img/principal_explicatricks.png")}
                  style={{
                    width: width <= 768 ? 250 : 1.5 * 250,
                    height: "100%",
                  }}
                  resizeMode="contain"
                />
              </View>
              {/* Slide 5 */}
              <View style={styles.slide}>
                <Image
                  source={require("../../../assets/img/principal_butec.png")}
                  style={{
                    width: width <= 768 ? 200 : 1.5 * 200,
                    height: "100%",
                  }}
                  resizeMode="contain"
                />
              </View>
            </Swiper>
          </View>
          <TextContainer>
            <Title>Créditos</Title>
            <Description>
              O aplicativo foi desenvolvido por pesquisadores da UFMG e UFSC,
              com apoio da Fundação Maria Cecília Souto Vidigal.
            </Description>
          </TextContainer>
        </View>
      </Container>
    </SafeAreaView>
  );
};

export default Credits;
