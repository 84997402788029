import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  margin: 0px 20px 20px 20px;
  background-color: ${Colors.background};
`;

export const MainTitle = styled.Text`
  flex-shrink: 1;
  font-family: Muli_700Bold;
  font-size: 20px;
  color: ${Colors.ultraDarkMain};
  margin: 20px 20px;
  text-align: center;
`;

export const DescriptionText = styled.Text`
  flex-shrink: 1;
  font-family: Muli_400Regular;
  font-size: 18px;
  color: ${Colors.darkGray};
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
`;

export const BibliographyText = styled.Text`
  flex-shrink: 1;
  font-family: Muli_400Regular;
  font-size: 11px;
  color: ${Colors.darkGray};
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
`;

export const QuestionContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const VideoCard = styled.View`
  margin-top: 15px;
  padding: 10px 10px 15px 10px;
  border-radius: 5px;
  background-color: ${Colors.white};
`;

export const ButtonsContainer = styled.View`
  justify-content: flex-end;
`;

export const Separator = styled.View`
  height: 10px;
`;

export const ResultTitle = styled.Text`
  flex-shrink: 1;
  font-family: Muli_700Bold;
  font-size: 24px;
  color: ${Colors.black};
  margin: 10px 0px 30px 0px;
`;

export const ResultSubTitle = styled.Text`
  flex-shrink: 1;
  font-family: Muli_400Regular;
  font-size: 14px;
  color: ${Colors.darkGray};
  margin-bottom: 10px;
`;

export const ResultCard = styled.View`
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 20px 25px;
  border-radius: 5px;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.1)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
`;

export const ResultCardText = styled.Text`
  flex-shrink: 1;
  font-family: Muli_700Bold;
  font-size: 16px;
  color: ${Colors.darkGray};
`;
