import { Animated } from "react-native";
import styled from "styled-components/native";
import Colors from "../../utils/Colors";

interface ProgressBarProps {
  width: number;
}

export const BackgroundBar = styled.View`
  height: 8px;
  width: 100%;
  border-radius: 8px;
  background-color: ${Colors.mediumGray};
  overflow: hidden;
`;

export const FrontBar = styled(Animated.View)<ProgressBarProps>`
  position: absolute;
  height: 8px;
  width: 100%;
  border-radius: 8px;
  background-color: ${Colors.main};
  overflow: hidden;
`;
// width: ${(props: ProgressBarProps) => props.width + "%"};
