import React, { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Modal,
  TouchableHighlight,
  TextStyle,
} from "react-native";
import { Feather } from "@expo/vector-icons";

import Colors from "../../utils/Colors";

import {
  Container,
  SelectedText,
  ModalContainer,
  ModalOverlay,
  ModalContent,
  ModalItemList,
  ModalText,
  ModalItem,
  DropdownItemList,
  DropdownItem,
  DropdownText,
} from "./styles";

interface Item {
  label: string;
  value: string;
  untouchable?: boolean;
  textStyle?: TextStyle;
}

interface PickerProps {
  mode?: "dropdown" | "dialog";
  items: Item[];
  defaultValue?: string;
  onChangeItem: (item: Item) => void;
  // Paremeter from previous component, keep it to not break other pages
  zIndex?: number;
}

const SelectPicker: React.FC<PickerProps> = props => {
  props = { ...{ mode: "dropdown" }, ...props };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [selected, setSelected] = useState("");

  const placeholder = "Selecione uma opção";

  useEffect(() => {
    const selectedItem = props.items.find(
      item => item.value === props.defaultValue
    );
    if (selectedItem) setSelected(selectedItem.label);
  }, []);

  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          if (props.mode === "dialog") setIsModalVisible(true);
          else setIsOpened(!isOpened);
        }}>
        <Container>
          <SelectedText isPlaceholder={selected === ""}>
            {selected !== "" ? selected : placeholder}
          </SelectedText>
          <Feather name="chevron-down" size={24} color={Colors.mediumGray} />
        </Container>
      </TouchableOpacity>
      {props.mode === "dropdown" && isOpened && (
        <DropdownItemList style={{ maxHeight: 150 }} nestedScrollEnabled={true}>
          {props.items.map((item, index) => (
            <DropdownItem
              key={index}
              disabled={item.untouchable}
              underlayColor={Colors.mediumGray}
              activeOpacity={0.6}
              onPress={() => {
                setIsModalVisible(false);
                setIsOpened(false);
                setSelected(item.label);
                props.onChangeItem(item);
              }}>
              <DropdownText style={item.textStyle}>{item.label}</DropdownText>
            </DropdownItem>
          ))}
        </DropdownItemList>
      )}
      {props.mode === "dialog" && (
        <ModalContainer>
          <Modal
            animationType="fade"
            transparent={true}
            visible={isModalVisible}
            statusBarTranslucent={true}
            onRequestClose={() => {
              setIsModalVisible(!isModalVisible);
            }}>
            <TouchableWithoutFeedback
              onPress={() => setIsModalVisible(!isModalVisible)}>
              <ModalOverlay>
                <ModalContent>
                  <ModalItemList>
                    {props.items.map((item, index) => (
                      <ModalItem
                        key={index}
                        disabled={item.untouchable}
                        underlayColor={Colors.mediumGray}
                        activeOpacity={0.6}
                        onPress={() => {
                          setIsModalVisible(false);
                          setSelected(item.label);
                          props.onChangeItem(item);
                        }}>
                        <ModalText style={item.textStyle}>
                          {item.label}
                        </ModalText>
                      </ModalItem>
                    ))}
                  </ModalItemList>
                </ModalContent>
              </ModalOverlay>
            </TouchableWithoutFeedback>
          </Modal>
        </ModalContainer>
      )}
    </View>
  );
};

export default SelectPicker;
