import styled from "styled-components/native";
import Icon from "react-native-vector-icons/MaterialIcons";

export const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const CloseButton = styled(Icon)`
  display: flex;
  background-color: transparent;
  padding: 0px;
`;
