import api from './api';

import { Child } from '../database/Models';

export const getChildren = async () => {
    try {
        const response = await api.get('/children');
        return response;

    } catch (err) {
        return err.response;
    }
};

export const createChild = async (child: Child) => {
    try {
        const response = await api.post('/children/store', child);
        return response;

    } catch (err) {
        return err.response;
    }
};

export const updateChild = async (child: Child) => {
    try {
        const response = await api.put('/children/update', child);
        return response;

    } catch (err) {
        return err.response;
    }
};

export const deleteChild = async (id: number) => {
    try {
        const response = await api.delete(`/children/delete/${id}`);
        return response;

    } catch (err) {
        return err.response;
    }
};
