import React from 'react';
import { View } from 'react-native';

import Colors from '../../utils/Colors';

import { Inner, Wrapper } from './styles';

interface CheckboxProps {
    selected: boolean;
    wrapperRadius: string;
    innerRadius: string;
    color?: string | null;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
    return (
        <Wrapper color={props.color ?? Colors.main} borderRadius={props.wrapperRadius}>
            {props.selected
                ? <Inner color={props.color ?? Colors.main} borderRadius={props.innerRadius} />
                : <View></View>
            }
        </Wrapper>
    );
};

export default Checkbox;
