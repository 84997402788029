import styled from "styled-components/native";

export const Container = styled.View`
  flex: 1;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
  padding-top: 0px;
`;

export const HeaderContainer = styled.View`
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled.View`
  height: 15%;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.View`
  width: 100%;
  justify-content: flex-start;
`;

export const ButtonsContainer = styled.View`
  flex: 2;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
`;

export const Title = styled.Text`
  font-family: Muli_800ExtraBold;
  font-size: 20px;
  color: #384b5e;
  margin-bottom: 10px;
`;

export const TitleInput = styled.Text`
  font-family: Muli_500Medium;
  font-size: 12px;
  color: #a3a3a3;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const SubTitle = styled.Text`
  font-family: Muli_500Medium;
  font-size: 16px;
  color: #bbb;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 15px;
`;
