import Cities from "../database/jsons/cities.json";

export const findByStateName = (stateName: string) => {
    let citiesArray = Array();
    Cities.forEach(city => {
        if (city.microrregiao.mesorregiao.UF.nome === stateName) {
            citiesArray.push(city);
        }
    });
    return citiesArray;
};

export const findById = (id: number) => {
    Cities.forEach(city => {
        if (city.id == id) {
            return city;
        }
    });
};

export default Cities;
