import React, { useState } from "react";
import {
  View,
  Text,
  ScrollView,
  ImageBackground,
  Modal,
  TouchableHighlight,
  TouchableWithoutFeedback,
  StyleSheet,
  useWindowDimensions,
} from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";

import Button from "../../components/Button";
import LogoNoText from "../../components/LogoNoText";

import Colors from "../../utils/Colors";

import { Container, LogoContainer, Dot, Circle } from "./styles";

interface OnBoardingProps {
  navigation: any;
}

const OnBoarding: React.FC<OnBoardingProps> = props => {
  const { width, height } = useWindowDimensions();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleNext = () => setIsModalVisible(!isModalVisible);

  const handleModal = async () => {
    if (isModalVisible) setIsModalVisible(!isModalVisible);

    try {
      AsyncStorage.setItem("@TediApp:onboardingViewed", "true");
      props.navigation.navigate("login");
    } catch (e) {
      console.log("Erro no async storage: " + e);
    }
  };

  const styles = StyleSheet.create({
    footerWrapper: {
      flexDirection: "row",
      justifyContent: "center",
    },
    buttonWrapper: {
      paddingVertical: 30,
    },
    modalContainer: {
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "center",
    },
    modal: {
      borderRadius: 8,
      width: width <= 768 ? width * 0.9 : width * 0.35,
      backgroundColor: Colors.modalBackground,
      padding: 30,
      paddingBottom: 20,
      alignSelf: "center",
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 2,
      elevation: 5,
    },
    modalOverlay: {
      justifyContent: "center",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
    },
  });

  return (
    <View style={{ height: "100%", backgroundColor: Colors.white }}>
      {/* Content */}
      <Container>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
          }}>
          <ImageBackground
            style={{
              width: 300,
              height: 300,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
            source={require("../../../assets/background_logo_shadow.png")}>
            <LogoContainer>
              <LogoNoText width={150} height={150} />
            </LogoContainer>
          </ImageBackground>
          <View
            style={{
              maxWidth: "60%",
            }}>
            <Text
              style={{
                textAlign: "center",
                fontFamily: "Muli_700Bold",
                fontSize: 20,
                marginBottom: 20,
                marginTop: height * 0.08,
              }}>
              Bem vindo ao Tedi!
            </Text>
            <Text
              style={{
                textAlign: "center",
                fontFamily: "Muli_400Regular",
                fontSize: 15,
                color: Colors.darkGray,
              }}>
              O TEDI é um aplicativo que ajuda a identificar as crianças que 
              precisam de avaliação mais aprofundada do seu desenvolvimento e comportamento, 
              além de oferecer vídeos de estimulação de 1 mês a 5 anos de vida.
            </Text>
          </View>
        </ScrollView>

        {/* Footer buttons */}
        <View style={styles.footerWrapper}>
          {/* "Próximo" button */}
          <View style={styles.buttonWrapper}>
            <TouchableHighlight
              onPress={handleNext}
              underlayColor={Colors.main}
              activeOpacity={0.6}
              style={{
                backgroundColor: Colors.main,
                paddingVertical: 8,
                paddingHorizontal: 5,
                borderRadius: 29,
                alignItems: "center",
                width: 110,
              }}>
              <Text
                style={{
                  color: Colors.white,
                  fontFamily: "Muli_700Bold",
                  fontSize: 15,
                }}>
                Próximo
              </Text>
            </TouchableHighlight>
          </View>
        </View>
      </Container>
      {/* Modal showed at the tend */}
      <View style={styles.modalContainer}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          statusBarTranslucent={true}
          onRequestClose={() => {
            setIsModalVisible(!isModalVisible);
          }}>
          <TouchableWithoutFeedback
            onPress={() => {
              setIsModalVisible(!isModalVisible);
            }}>
            <View style={styles.modalOverlay}>
              <View style={styles.modal}>
                <Text
                  style={{
                    fontFamily: "Muli_700Bold",
                    fontSize: 20,
                    marginBottom: 20,
                  }}>
                  Vamos lá
                </Text>

                <Text
                  style={{
                    fontFamily: "Muli_400Regular",
                    fontSize: 15,
                    color: Colors.darkGray,
                    textAlign: "center",
                    marginBottom: 30,
                  }}>
                  No primeiro acesso, você deve se cadastrar para a gente te
                  conhecer melhor e depois, cadastrar as crianças que você quer
                  avaliar. Você pode escolher quais questionários você quer usar
                  e como quer usar, mas a avaliação completa do comportamento,
                  desenvolvimento e do contexto familiar vai te ajudar a tomar
                  as melhores decisões. E não se esqueça de compartilhar os
                  vídeos de estimulação. Eles serão úteis para todas as
                  famílias!
                </Text>
                <Button text="Vamos lá" onPress={handleModal} />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    </View>
  );
};

export default OnBoarding;
