import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  padding: 20px;
  background-color: ${Colors.background};
`;

export const UserCard = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 5px;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.1)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
`;

interface UserAvatarProps {
  backgroundColor: string;
}

export const UserAvatar = styled.View<UserAvatarProps>`
  height: 100px;
  width: 100px;
  align-items: center;
  padding-top: 20px;
  border-radius: 50px;
  background-color: ${props => props.backgroundColor};
  shadow-color: "rgba(138, 149, 158, 0.15)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 2;
  overflow: hidden;
`;

export const UserContent = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const UserName = styled.Text`
  font-family: Muli_700Bold;
  font-size: 26px;
  color: ${Colors.black};
  text-align: center;
`;

export const UserAvaliations = styled.Text`
  font-family: Muli_700Bold;
  font-size: 14px;
  color: ${Colors.darkGray};
`;

export const UserOptions = styled.TouchableOpacity`
  flex-direction: row;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px 9px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.lightGray};
`;

export const UserOptionsText = styled.Text`
  font-family: Muli_700Bold;
  font-size: 14px;
  color: ${Colors.darkGray};
`;

export const AppVersion = styled.View`
  margin-top: auto;
  margin-bottom: 10px;
`;

export const AppVersionText = styled.Text`
  font-family: Muli_400Regular;
  font-size: 9px;
  color: ${Colors.darkGray};
  text-align: right;
`;

export const AppEmail = styled.Text`
  font-family: Muli_400Regular;
  font-size: 16px;
  color: ${Colors.darkGray};
  text-align: right;
`;

export const Button = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 5px;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.15)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 2;
`;

export const ButtonText = styled.Text`
  margin: 0px 20px;
  font-family: Muli_700Bold;
  font-size: 16px;
  color: ${Colors.darkGray};
`;
