import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  background-color: ${Colors.white};
  align-items: center;
  padding: 30px;
  padding-top: 10px;
`;

export const LogoContainer = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.View`
  padding: 30px 0px;
`;

export const Title = styled.Text`
  margin-bottom: 20px;
  font-family: Muli_700Bold;
  font-size: 20px;
  color: ${Colors.ultraDarkMain};
  text-align: center;
`;

export const Description = styled.Text`
  font-family: Muli_400Regular;
  font-size: 15px;
  color: ${Colors.darkGray};
  text-align: center;
`;
