import styled from "styled-components/native";
import PickerModal from "react-native-picker-modal-view";

import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  align-items: center;
  background-color: white;
  padding: 30px;
  padding-top: 10px;
`;

export const LogoContainer = styled.View`
  height: 15%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface InputContainerProps {
  marginTop: string;
}

export const InputContainer = styled.View<InputContainerProps>`
  width: 100%;
  justify-content: center;
  margin-top: ${(props: InputContainerProps) => props.marginTop};
`;

export const WarningText = styled.Text`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${Colors.black}
  font-family: Muli_700Bold;
  font-size: 12px;
  text-align: center;
`;

export const TitleInput = styled.Text`
  flex-shrink: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${Colors.darkGray};
  font-size: 11px;
  font-family: Muli_400Regular;
  text-align: justify;
`;

export const TermListContainer = styled.View`
  margin-top: 10px;
`;

export const TermContainer = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const ButtonsContainer = styled.View`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const CustomSelect = styled.View`
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid ${Colors.mediumGray};
  border-radius: 5px;
`;

export const Picker = styled(PickerModal)`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  border: 1px solid ${Colors.mediumGray};
  z-index: 9995;
`;
