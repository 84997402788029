// import { ColorValue } from "react-native";
import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  background-color: ${Colors.background};
`;

export const IndicatorContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px;
`;

interface DecisionGuideIndicatorProps {
  color: string;
  active: boolean;
}

export const Indicator = styled.View<DecisionGuideIndicatorProps>`
  height: 20px;
  width: 20px;
  margin: 0px 8px;
  border-radius: 20px;
  background-color: ${(props: DecisionGuideIndicatorProps) => props.color};
  opacity: ${(props: DecisionGuideIndicatorProps) => (props.active ? 1 : 0.15)};
  elevation: ${(props: DecisionGuideIndicatorProps) => (props.active ? 5 : 0)};
  shadow-color: ${(props: DecisionGuideIndicatorProps) =>
    props.active ? props.color : "#0000"};
  shadow-opacity: ${(props: DecisionGuideIndicatorProps) =>
    props.active ? 0.9 : 0};
  shadow-offset: ${(props: DecisionGuideIndicatorProps) =>
    props.active ? "0px 5px" : "0px 0px"};
  shadow-radius: ${(props: DecisionGuideIndicatorProps) =>
    props.active ? "10px" : "0px"};
`;

export const DecisionGuideText = styled.Text`
  flex-shrink: 1;
  font-family: Muli_400Regular;
  font-size: 14px;
  color: ${Colors.darkGray};
  margin-bottom: 10px;
  text-align: left;
`;

export const CardsContainer = styled.View`
  flex: 1;
  margin: 20px;
  margin-top: 0px;
`;

export const DecisionGuideCard = styled.View`
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 20px 25px;
  border-radius: 5px;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.1)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
`;

export const DecisionGuideCardLink = styled.TouchableOpacity`
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 20px 25px;
  border-radius: 5px;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.1)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
`;

export const DecisionGuideCardText = styled.Text`
  flex-shrink: 1;
  font-family: Muli_700Bold;
  font-size: 16px;
  color: ${Colors.darkGray};
`;

export const ButtonsContainer = styled.View`
`;

export const ModalContainer = styled.View`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const ModalOverlay = styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.View`
  width: 90%;
  border-radius: 8px;
  background-color: ${Colors.modalBackground};
  padding: 30px;
  align-items: center;
  shadow-color: #000;
  shadow-offset: 0px 2px;
  shadow-opacity: 0.25;
  shadow-radius: 2px;
  elevation: 5;
`;

export const ModalHeader = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const ModalTitle = styled.Text`
  color: ${Colors.black};
  font-family: Muli_700Bold;
  font-size: 24px;
`;

export const ModalText = styled.Text`
  margin-bottom: 30px;
  font-family: Muli_500Medium;
  font-size: 18px;
  color: ${Colors.darkGray};
  text-align: center;
`;

export const ModalButtonContainer = styled.View`
  flex-direction: row;
`;

export const ModalButton = styled.View`
  flex: 1;
`;

export const VideoCard = styled.View`
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: ${Colors.white};
`;
