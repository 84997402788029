import api from './api';

import { Form } from '../database/Models';

export const getForm = async () => {
    try {
        const response = await api.get('/forms');
        return response;

    } catch (err) {
        return err.response;
    }
};

export const createForm = async (form: Form) => {
    try {
        const response = await api.post('/forms/store', form);
        return response;

    } catch (err) {
        return err.response;
    }
};

export const updateForm = async (form: Form) => {
    try {
        const response = await api.put('/forms/update', form);
        return response;

    } catch (err) {
        return err.response;
    }
};

export const updateFormWithEntries = async (form: Form, stage: string) => {
    try {
        const response = await api.put('/forms/update/with_entries', {...form, entries_stage: stage});
        return response;

    } catch (err) {
        return err.response;
    }
};

export const deleteForm = async (id: number) => {
    try {
        const response = await api.delete(`/forms/delete/${id}`);
        return response;

    } catch (err) {
        return err.response;
    }
};
