import React from "react";
import {
  ActivityIndicator,
  GestureResponderEvent,
  Text,
  TouchableHighlight,
  ColorValue,
  View,
} from "react-native";
import Colors from "../../utils/Colors";

import { Container, TextBtn } from "./styles";

interface props {
  text: string;
  textColor?: string;
  loading?: boolean;
  onPress: (event: GestureResponderEvent) => void;
  underlayColor?: string;
  backgroundColor?: string;
  shadowColor?: ColorValue;
  shadowOffset?: { width: number; height: number };
  shadowRadius?: number;
  elevation?: number;
  disabled?: boolean;
}

const Button: React.FC<props> = props => {
  return (
    <TouchableHighlight
      onPress={props.onPress}
      underlayColor={props.underlayColor ?? Colors.main}
      activeOpacity={0.6}
      disabled={props.disabled}
      style={{
        minHeight: 50,
        borderRadius: 5,
        backgroundColor: props.disabled
          ? Colors.darkGray
          : props.backgroundColor ?? Colors.main,
        flexDirection: "row",
        shadowColor: props.shadowColor ?? "rgba(138, 149, 158, 0.1)",
        shadowOffset: props.shadowOffset ?? { width: 0, height: 5 },
        shadowRadius: props.shadowRadius ?? 10,
        elevation: props.elevation ?? 2,
        width: "100%",
        flexBasis: 0
      }}>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}>
        {props.loading === true ? (
          <View style={{ maxHeight: 50 }}>
            <ActivityIndicator size="large" color={Colors.white} />
          </View>
        ) : (
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              flexWrap: "wrap",
            }}>
            <Text
              style={{
                flex: 1,
                fontFamily: "Muli_700Bold",
                fontSize: 16,
                textAlign: "center",
                color: props.textColor ?? Colors.white,
              }}
              numberOfLines={3}>
              {props.text}
            </Text>
          </View>
        )}
        {/* Support for checkbox */}
        {props.children && (
          <View style={{ marginLeft: 5 }}>{props.children}</View>
        )}
      </View>
    </TouchableHighlight>
  );
};

export default Button;
