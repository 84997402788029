import React, { useEffect, useRef, useState } from "react";
import { Animated } from "react-native";
import {} from "react-native-reanimated";
import { BackgroundBar, FrontBar } from "./styles";

interface ProgressBarProps {
  percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = props => {
  const [width, setWidth] = useState(0);

  const progressAnim = useRef(new Animated.Value(-500)).current;

  useEffect(() => {
    Animated.timing(progressAnim, {
      toValue: width * (props.percentage / 100) - width,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [props.percentage, width]);

  return (
    <BackgroundBar>
      <FrontBar
        onLayout={e => {
          const newWidth = e.nativeEvent.layout.width;

          setWidth(newWidth);
        }}
        style={{ transform: [{ translateX: progressAnim }] }}
      />
    </BackgroundBar>
  );
};

export default ProgressBar;
