import * as React from "react";
import { Image } from "react-native";

interface Props {
  width: number | string;
  height: number | string;
}

const Logo = ({ width, height }: Props) => {
  return (
    <Image
      style={{ width: width, height: height }}
      resizeMode="contain"
      source={require("../../../assets/tedi_logo.png")}
    />
  );
};

export default Logo;
