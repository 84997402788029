import React, { useEffect, useState } from "react";
import { TextStyle } from "react-native";

import { Text, TextUnderline } from "./styles";

interface QuestionTextProps {
  text: string;
  style?: TextStyle;
}

const QuestionText: React.FC<QuestionTextProps> = props => {
  const [textBegin, setTextBegin] = useState("");
  const [textEnd, setTextEnd] = useState("");
  const [underlineText, setUnderlineText] = useState("");

  useEffect(() => {
    const underlineStart = props.text.search("<u>");
    const underlineEnd = props.text.search("</u>");

    setTextBegin(
      props.text.substring(0, underlineStart).replace(/<u>|<\/u>/, "")
    );
    setUnderlineText(
      props.text
        .substring(underlineStart, underlineEnd)
        .replace(/<u>|<\/u>/, "")
    );
    setTextEnd(props.text.substring(underlineEnd).replace(/<u>|<\/u>/, ""));
  }, [props.text]);

  return (
    <>
      <Text style={props.style}>
        {textBegin}
        <TextUnderline style={props.style}>{underlineText}</TextUnderline>
        {textEnd}
      </Text>
    </>
  );
};

export default QuestionText;
