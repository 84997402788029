import React, { useContext, useEffect, useRef, useState } from "react";
import {
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from "react-native";

import { SafeAreaView } from "react-native-safe-area-context";
import { Toast } from "native-base";
import { format } from "date-fns";

import { ChildrenContext } from "../../contexts/ChildrenContext";

import { Child } from "../../database/Models";

import Colors from "../../utils/Colors";

import Input from "../../components/Input";
import InputError from "../../components/InputError";
import Button from "../../components/Button";
import DropDownComponent from "../../components/DropDown";

import {
  Container,
  InputContainer,
  TitleInput,
  ButtonsContainer,
} from "./styles";
import { useWindowDimensions } from "react-native";

interface UpdateChildProps {
  navigation: any;
}

const UpdateChild: React.FC<UpdateChildProps> = props => {
  const { width } = useWindowDimensions();

  const { selectedChild, updateChild, isChildrenLoading } =
    useContext(ChildrenContext);

  const child = selectedChild;

  const motherNameInputRef: React.RefObject<TextInput> = useRef(null);

  const [name, setName] = useState(child.name);
  const [motherName, setMotherName] = useState(child.mother_name);
  const [gender, setGender] = useState(child.gender);
  const [skinColor, setSkinColor] = useState(child.skin_color);
  const [birthdate, setBirthdate] = useState(
    format(new Date(child.birth_date), "dd/MM/yyyy").toString()
  );
  const [gestationalAge, setGestationalAge] = useState(
    child.gestational_age.toString()
  );

  const [nameError, setNameError] = useState("");
  const [motherNameError, setMotherNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [skinColorError, setSkinColorError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [gestationalAgeError, setGestationalAgeError] = useState("");

  const handleSubmit = async () => {
    // Set and unset errors on user submit
    if (name.trim() === "") {
      setNameError("Preencha o campo nome");
      return;
    }
    setNameError("");
    if (motherName.trim() === "") {
      setMotherNameError("Preencha o campo nome da mãe");
      return;
    }
    setMotherNameError("");
    if (gender === "") {
      setGenderError("Selecione uma opção no campo gênero");
      return;
    }
    setGenderError("");
    if (skinColor === "") {
      setSkinColorError("Selecione uma opção no campo gênero");
      return;
    }
    setSkinColorError("");
    if (birthdate.length < 10) {
      setBirthdateError("Digite uma data válida");
      return;
    }
    setBirthdateError("");
    if (gestationalAge === "") {
      setGestationalAgeError("Preencha o campo idade gestacional");
      return;
    }
    setGestationalAgeError("");

    // Split date to later convert to yyyy/mm/gestationalAge.substr(0, gestationalAge.indexOf(' '))
    const birthdateParts = birthdate.split("/");

    const child: Child = {
      id: selectedChild.id,
      name: name.trim(),
      mother_name: motherName.trim(),
      gender,
      skin_color: skinColor,
      birth_date: new Date(
        +parseInt(birthdateParts[2]),
        parseInt(birthdateParts[1]) - 1,
        +parseInt(birthdateParts[0])
      ),
      gestational_age: parseInt(gestationalAge),
      user_id: selectedChild.user_id,
    };

    const response = await updateChild(child);

    if (response && response.status === 200) {
      props.navigation.goBack();
    } else {
      Toast.show({
        title: response
          ? response.data.error.message
          : "Não foi possível avançar. Verifique sua conexão e tente novamente",
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });
    }
  };

  useEffect(() => {
    if (!selectedChild) {
      props.navigation.navigate("Home");
    }
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS == "ios" ? "padding" : "height"}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Container>
            <InputContainer>
              <TitleInput>Nome da criança</TitleInput>
              <Input
                placeholder="Digite o nome da criança"
                keyboard="default"
                return="next"
                onChangeText={value => setName(value)}
                value={name}
                onSubmit={() =>
                  motherNameInputRef.current &&
                  motherNameInputRef.current.focus()
                }
              />
              {nameError !== "" && <InputError text={nameError} />}
              <TitleInput>Nome da mãe</TitleInput>
              <Input
                inputRef={motherNameInputRef}
                keyboard="default"
                return="next"
                placeholder="Digite o nome da mãe da criança"
                value={motherName}
                onChangeText={text => setMotherName(text)}
                blurOnSubmit={true}
                onSubmit={() => { }}
              />
              {motherNameError !== "" && <InputError text={motherNameError} />}
              <TitleInput>Gênero</TitleInput>
              <DropDownComponent
                defaultValue={gender}
                items={[
                  { label: "Masculino", value: "male" },
                  { label: "Feminino", value: "female" },
                  { label: "Outro", value: "other" },
                ]}
                zIndex={9000}
                onChangeItem={(item: { value: string }) =>
                  setGender(item.value)
                }
              />
              {genderError !== "" && <InputError text={genderError} />}
              <TitleInput>Cor ou raça</TitleInput>
              <DropDownComponent
                defaultValue={skinColor}
                items={[
                  { label: "Branca", value: "branca" },
                  { label: "Preta", value: "preta" },
                  { label: "Amarela", value: "amarela" },
                  { label: "Parda", value: "parda" },
                  { label: "Indígena", value: "indigena" },
                  { label: "Sem declaração", value: "sem declaracao" },
                ]}
                zIndex={8000}
                onChangeItem={(item: { value: string }) =>
                  setSkinColor(item.value)
                }
              />
              {skinColorError !== "" && <InputError text={skinColorError} />}
              <TitleInput>Data de nascimento</TitleInput>
              <Input
                mask="datetime"
                keyboard="numeric"
                return="next"
                placeholder="dd/mm/aaaa"
                value={birthdate}
                onChangeText={text => setBirthdate(text)}
                onSubmit={() => { }}
                blurOnSubmit={true}
              />
              {birthdateError !== "" && <InputError text={birthdateError} />}
              <TitleInput>Com quantas semanas a criança nasceu?</TitleInput>
              <DropDownComponent
                defaultValue={gestationalAge}
                items={[
                  { label: 'Não sabe informar', value: '0' },
                  ...Array.from(Array(22), (_, i) => {
                    return {
                      label: (44 - i).toString(),
                      value: (44 - i).toString(),
                    };
                  })
                ]}
                zIndex={7000}
                onChangeItem={(item: { value: string }) =>
                  setGestationalAge(item.value)
                }
              />
              {gestationalAgeError !== "" && (
                <InputError text={gestationalAgeError} />
              )}
            </InputContainer>
            <ButtonsContainer>
              <Button
                text="Atualizar"
                onPress={handleSubmit}
                loading={isChildrenLoading}
              />
            </ButtonsContainer>
          </Container>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default UpdateChild;
