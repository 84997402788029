import api from './api';

import { User } from '../database/Models';

export const signIn = async (email: String, password: String) => {
    try {
        const response = await api.post('/sessions/signin', {
            email,
            password
        });

        api.defaults.headers['Authorization'] = 'Bearer ' + response.data.token;

        return response;

    } catch (err) {
        return err.response;
    }
};

export const signUp = async (user: User) => {
    try {
        const response = await api.post('/users/store', user);

        api.defaults.headers['Authorization'] = 'Bearer ' + response.data.token;

        return response;

    } catch (err) {
        return err.response;
    }
};
