import React from "react";
import { Text, View, TouchableOpacity, Share, Platform } from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Linking from "expo-linking";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import * as WebBrowser from "expo-web-browser";
import { Buffer } from "buffer";
import Animated from "react-native-reanimated";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { format } from "date-fns";
import { FontAwesome5 } from "@expo/vector-icons";

import { Child, Form } from "../../database/Models";
import { getMonthsDiff } from "../../utils/Functions";
import Colors from "../../utils/Colors";

import api, { baseURL } from "../../services/api";

import { Card, Avatar, Content, Title, Subtitle } from "./styles";

interface ItemChild {
  item: Child;
  onPress: any;
  onDelete: any;
  backgroundColor: string;
}

interface ItemForm {
  item: Form;
  onPress: any;
  onDelete: any;
}

type ItemCardProps = ItemForm | ItemChild;

const AnimatedIcon = Animated.createAnimatedComponent(FontAwesome5);

const ItemCard: React.FC<ItemCardProps> = props => {
  const isChild = (object: any): object is Child => {
    return "mother_name" in object;
  };

  const generatePdf = async (item: Child | Form) => {
    const token = await AsyncStorage.getItem("@TediApp:token");

    if (isChild(item)) {
      // const uri = `${baseURL}/children/pdf?auth=${token}&identifier=${item.id}`;
      // WebBrowser.openBrowserAsync(uri);
    } else {
      const uri = `${baseURL}/forms/pdf?auth=${token}&identifier=${item.id}`;
      WebBrowser.openBrowserAsync(uri);
    }
  };

  const renderRightAction = (
    component: any,
    onPress: any,
    color: string,
    text: string,
    index: number,
    borderLeft?: number,
    borderRight?: number
  ) => {
    return (
      <Animated.View
        key={index}
        style={{
          flex: 1,
          height: 90,
        }}>
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={onPress}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: color,
            borderTopRightRadius: borderRight ?? 0,
            borderBottomRightRadius: borderRight ?? 0,
            borderTopLeftRadius: borderLeft ?? 0,
            borderBottomLeftRadius: borderLeft ?? 0,
          }}>
          {component}
          <Text
            style={{
              color: Colors.white,
              fontFamily: "Muli_500Medium",
            }}>
            {text}
          </Text>
        </TouchableOpacity>
      </Animated.View>
    );
  };

  const renderRightActions = () => {
    const icons = [
      {
        component: <AnimatedIcon name="file-contract" size={24} color="#fff" />,
        action: () => generatePdf(props.item),
        color: Colors.main,
        text: "Exportar",
      },
      {
        component: <AnimatedIcon name="trash" size={24} color="#fff" />,
        action: props.onDelete,
        color: "#eb3434",
        text: "Apagar",
      },
    ];

    if (isChild(props.item)) {
      return (
        <View
          style={{
            width: 80,
            marginRight: 20,
            marginLeft: -20,
            flexDirection: "row",
          }}>
          {renderRightAction(
            icons[1].component,
            icons[1].action,
            icons[1].color,
            icons[1].text,
            0,
            5,
            5
          )}
        </View>
      );
    }

    return (
      <View
        style={{
          width: 160,
          marginRight: 20,
          marginLeft: -20,
          flexDirection: "row",
        }}>
        {icons.map((icon, index) =>
          renderRightAction(
            icon.component,
            icon.action,
            icon.color,
            icon.text,
            index,
            index === 0 ? 5 : undefined,
            index === 1 ? 5 : undefined
          )
        )}
      </View>
    );
  };

  return (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <Card
        style={{ flex: 1 }}
        paddingLeft={isChild(props.item) ? "20px" : "0px"}
        onPress={props.onPress}>
        {"backgroundColor" in props && (
          <Avatar backgroundColor={props.backgroundColor}>
            <FontAwesome5 name="user-alt" size={40} color={Colors.white} />
          </Avatar>
        )}
        {isChild(props.item) ? (
          <Content>
            <Title>{props.item.name}</Title>
            <Subtitle>{getMonthsDiff(props.item.birth_date)} Meses</Subtitle>
          </Content>
        ) : (
          <Content>
            <Title>Avaliação de {props.item.months} meses</Title>
            <Subtitle>
              {format(new Date(props.item.date), "dd/MM/yyyy")}
            </Subtitle>
          </Content>
        )}
      </Card>
      {renderRightActions()}
    </View>
  );
};

export default ItemCard;
