import States from "../database/jsons/states.json";

export const findById = (id: number) => {
    States.forEach(state => {
        if (state.id === id) {
            return state;
        }
    });
};

export default States;
