import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  padding-top: 20px;
  margin-bottom: 20px;
  background-color: ${Colors.background};
`;

export const ChildCard = styled.View`
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  padding: 20px;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.1)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
  border-radius: 5px;
`;

interface ChildAvatarProps {
  backgroundColor: string;
}

export const ChildAvatar = styled.View<ChildAvatarProps>`
  display: flex;
  height: 100px;
  width: 100px;
  padding-top: 20px;
  border-radius: 50px;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  shadow-color: "rgba(138, 149, 158, 0.15)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
`;

export const ChildContent = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const ChildName = styled.Text`
  text-align: center;
  font-family: Muli_700Bold;
  font-size: 26px;
  color: ${Colors.black};
`;

export const ChildMonths = styled.Text`
  font-family: Muli_700Bold;
  font-size: 14px;
  color: ${Colors.darkGray};
`;

export const ChildOptions = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px 9px;
  border-radius: 4px;
  background-color: ${Colors.lightGray};
`;

export const ChildOptionsText = styled.Text`
  font-family: Muli_700Bold;
  font-size: 14px;
  color: ${Colors.darkGray};
`;

export const SurveyCard = styled.TouchableOpacity`
  flex-direction: row;
  height: 84px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.1)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
  border-radius: 5px;
`;

export const SurveyContent = styled.View`
  flex: 1;
  padding-left: 25px;
`;

export const SurveyName = styled.Text`
  font-family: Muli_700Bold;
  font-size: 20px;
  color: ${Colors.black};
`;

export const SurveyDate = styled.Text`
  font-family: Muli_700Bold;
  font-size: 14px;
  color: ${Colors.darkGray};
`;

export const SurveyOptions = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
`;

export const ButtonsContainer = styled.View`
  justify-content: flex-end;
  margin: 20px;
`;

export const ModalContainer = styled.View`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const ModalOverlay = styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.View`
  width: 90%;
  border-radius: 8px;
  background-color: ${Colors.modalBackground};
  padding: 30px;
  align-items: center;
  shadow-color: #000;
  shadow-offset: 0px 2px;
  shadow-opacity: 0.25;
  shadow-radius: 2px;
  elevation: 5;
`;

export const ModalTitle = styled.Text`
  margin-bottom: 20px;
  color: #eb1a20;
  font-family: Muli_700Bold;
  font-size: 20px;
`;

export const ModalText = styled.Text`
  margin-bottom: 30px;
  font-family: Muli_700Bold;
  font-size: 15px;
  color: ${Colors.black};
  text-align: justify;
`;

export const ModalButtonContainer = styled.View`
  flex-direction: row;
`;

export const ModalButton = styled.View`
  flex: 1;
`;
