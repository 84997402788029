import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 27px;
  margin-right: 27px;
  background-color: transparent;
`;

export const LogoContainer = styled.View`
  background-color: white;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 15px rgba(138, 149, 158, 0.15);
  border-radius: 29px;
  width: 75%;
  height: 75%;
`;

export const Dot = styled.View`
  background-color: ${(props: { active: any }) =>
    props.active ? "#007aff" : "#86B5E3"};
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 3px;
`;

export const Circle = styled.View`
  width: ${(props: { size: String }) => props.size};
  height: ${(props: { size: String }) => props.size};
  border-radius: ${(props: { radius: String }) => props.radius};
  background: #afd0f0;
  opacity: 1;
  z-index: 9;
`;

export const ModalContainer = styled.View`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const ModalOverlay = styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.View`
  width: 90%;
  border-radius: 8px;
  background-color: ${Colors.modalBackground};
  padding: 30px;
  align-items: center;
  shadow-color: #000;
  shadow-offset: 0px 2px;
  shadow-opacity: 0.25;
  shadow-radius: 2px;
  elevation: 5;
`;

export const ModalHeader = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const ModalTitle = styled.Text`
  color: ${Colors.black};
  font-family: Muli_700Bold;
  font-size: 24px;
`;

export const ModalText = styled.Text`
  margin-bottom: 30px;
  font-family: Muli_500Medium;
  font-size: 18px;
  color: ${Colors.darkGray};
  text-align: center;
`;

export const ModalButtonContainer = styled.View`
  flex-direction: row;
`;

export const ModalButton = styled.View`
  flex: 1;
`;

export const VideoCard = styled.View`
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: ${Colors.white};
`;
