import styled from "styled-components/native";
import PickerModal from "react-native-picker-modal-view";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  padding: 0px 30px;
  background-color: ${Colors.white};
`;

export const InputContainer = styled.View`
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

interface TitleInputProps {
  marginTop?: string;
}

export const TitleInput = styled.Text<TitleInputProps>`
    font-family: Muli_400Regular;
    font-size: 11px;
    color: #A3A3A3;
    flex-shrink: 1;
    margin-top: ${props => props.marginTop ?? "10px"}
    margin-bottom: 10px;
`;

export const ButtonsContainer = styled.View`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const CustomSelect = styled.View`
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid ${Colors.mediumGray};
  border-radius: 5px;
`;

export const Picker = styled(PickerModal)`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  border: 1px solid ${Colors.mediumGray};
  z-index: 9995;
`;
