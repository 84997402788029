import React, { useContext, useRef, useState } from "react";
import {
  Dimensions,
  Text,
  ScrollView,
  View,
  TouchableOpacity,
  TextInput,
  useWindowDimensions,
} from "react-native";

import { SafeAreaView } from "react-native-safe-area-context";
import { TextInputMask } from "react-native-masked-text";
import { IModalListInDto } from "react-native-picker-modal-view/dist/Interfaces";
import Icon from "react-native-vector-icons/Feather";
import { format } from "date-fns";
import { Toast } from "native-base";

import { AuthContext } from "../../contexts/AuthContext";

import * as userService from "../../services/user";
import States from "../../services/states";
import { findByStateName } from "../../services/cities";
import Colors from "../../utils/Colors";
import { User } from "../../database/Models";

import {
  Container,
  LogoContainer,
  InputContainer,
  WarningText,
  TitleInput,
  ButtonsContainer,
  CustomSelect,
  Picker,
} from "./styles";

import Close from "../../components/Close";
import Logo from "../../components/Logo";
import Input from "../../components/Input";
import Button from "../../components/Button";
import InputError from "../../components/InputError";
import DropDownComponent from "../../components/DropDown";

interface PickerProps {
  Name: string;
  Value: string;
  Code: string;
  Id: number;
}

interface SignUpProps {
  route: any;
  navigation: any;
}

const SignUp: React.FC<SignUpProps> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser } = useContext(AuthContext);

  const { width, height } = useWindowDimensions();

  const emailInputRef: React.RefObject<TextInput> = useRef(null);
  const passwordInputRef: React.RefObject<TextInput> = useRef(null);
  const birthdateInputRef: React.RefObject<TextInputMask> = useRef(null);

  const dropdownStates = States.map(function (state) {
    return {
      Name: state.nome,
      Value: state.nome,
      Code: state.sigla,
      Id: state.id,
    };
  });
  const [dropdownCities, setDropdownCities] = useState<PickerProps[]>([]);

  const userType = user?.type;

  // All user related fields
  const [name, setName] = useState(user ? user.name : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [password, setPassword] = useState("");
  const [birthdate, setBirthdate] = useState(
    user ? format(new Date(user.birth_date), "dd/MM/yyyy").toString() : ""
  );
  const [gender, setGender] = useState(user ? user.gender : "");
  const [schooling, setSchooling] = useState(user ? user.schooling : "");
  const [state, setState] = useState(user ? user.state : "");
  const [city, setCity] = useState(user ? user.city : "");
  // User type specific fields
  const [occupation, setOccupation] = useState(user ? user.occupation : "");
  const [occupationLocation, setOccupationLocation] = useState(
    user ? user.occupation_location : ""
  );
  const [hasSpecificTraining, setHasSpecificTraining] = useState(
    user ? user.has_specific_training : ""
  );
  const [caregiverType, setCaregiverType] = useState(
    user ? user.caregiver_type : ""
  );
  // "Other" related fields
  const [otherOccupation, setOtherOccupation] = useState(
    user ? user.occupation : ""
  );
  const [otherOccupationLocation, setOtherOccupationLocation] = useState(
    user ? user.occupation_location : ""
  );
  const [otherCaregiverType, setOtherCaregiverType] = useState(
    user ? user.caregiver_type : ""
  );

  // Error handling
  // Error fields for all the users
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [schoolingError, setSchoolingError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  // Error fields that are user type specific
  const [occupationError, setOccupationError] = useState("");
  const [occupationLocationError, setOccupationLocationError] = useState("");
  const [hasSpecificTrainingError, setHasSpecificTrainingError] = useState("");
  const [caregiverTypeError, setCaregiverTypeError] = useState("");

  const handleState = (item: IModalListInDto) => {
    setState(item.Value.toString());

    const cities = findByStateName(item.Value.toString());

    if (cities) {
      const newDropdownCities: PickerProps[] = cities.map(city => {
        return {
          Name: city.nome,
          Value: city.nome,
          Code: city.nome,
          Id: city.id,
        };
      });

      setDropdownCities(newDropdownCities);
    }

    return item;
  };

  const handleError = () => {
    // Set and unset errors on user submit
    if (name.trim() === "") {
      setNameError("Preencha o campo nome");
      return true;
    }
    setNameError("");
    if (email === "") {
      setEmailError("Preencha o campo e-mail");
      return true;
    }
    setEmailError("");
    if (birthdate.length < 10) {
      setBirthdateError("Digite uma data válida");
      return true;
    }
    setBirthdateError("");
    if (gender === "") {
      setGenderError("Selecione uma opção no campo gênero");
      return true;
    }
    setGenderError("");
    if (schooling === "") {
      setSchoolingError("Selecione uma opção no campo escolaridade");
      return true;
    }
    setSchoolingError("");

    if (userType === "caregiver") {
      if (caregiverType === "") {
        setCaregiverTypeError("Selecione uma opção no campo você é");
        return true;
      }
      if (caregiverType === "Outro" && otherCaregiverType === "") {
        setCaregiverTypeError("Preencha o campo você é");
        return true;
      }
      setCaregiverTypeError("");
    }
    if (userType === "professional") {
      if (occupation.trim() === "") {
        setOccupationError("Preencha o campo profissão");
        return true;
      }
      setOccupationError("");
      if (hasSpecificTraining === "") {
        setHasSpecificTrainingError(
          "Selecione uma opção no campo treinamento especifíco"
        );
        return true;
      }
      setHasSpecificTrainingError("");
    }
    if (
      userType === "professional" ||
      caregiverType === "Professor" ||
      caregiverType === "Pedagogo" ||
      caregiverType === "Assistente Social" ||
      caregiverType === "Outro"
    ) {
      if (occupationLocation === "") {
        setOccupationLocationError("Selecione uma opção no campo local");
        return true;
      }
      if (occupationLocation === "Outro" && otherOccupationLocation === "") {
        setOccupationLocationError("Preencha o campo local");
        return true;
      }
      setOccupationLocationError("");
    }

    if (state === "") {
      setStateError("Selecione uma opção no campo estado");
      return true;
    }
    setStateError("");
    if (city === "") {
      setCityError("Selecione uma opção no campo cidade");
      return true;
    }
    setCityError("");

    return false;
  };

  const handleUpdate = async () => {
    if (handleError()) return;
    setIsLoading(true);
    // Convert date to yyyy/mm/yy
    const birthdateParts = birthdate.split("/");

    // Handle "other" possibilities
    const occupationValue =
      occupation !== "Outro" ? occupation : otherOccupation.trim();
    const occupationLocationValue =
      occupationLocation !== "Outro"
        ? occupationLocation
        : otherOccupationLocation.trim();
    const caregiverTypeValue =
      caregiverType !== "Outro" ? caregiverType : otherCaregiverType.trim();

    if (user) {
      const object: User = user;

      object.name = name.trim();
      object.email = email;
      object.password = password;
      object.gender = gender;
      object.birth_date = new Date(
        +parseInt(birthdateParts[2]),
        parseInt(birthdateParts[1]) - 1,
        +parseInt(birthdateParts[0])
      );
      object.schooling = schooling;
      object.state = state;
      object.city = city;
      object.occupation = occupationValue;
      object.occupation_location = occupationLocationValue;
      object.has_specific_training = hasSpecificTraining === "true";
      object.caregiver_type = caregiverTypeValue;

      const response = await userService.update(object);

      if (response && response.status === 200) {
        Toast.show({
          title: "Conta atualizada com sucesso!",
          duration: 1500,
          isClosable: true,
          placement: width <= 1024 ? "bottom" : "bottom-left",
          padding: 4,
          marginLeft: width <= 1024 ? 0 : 20,
          status: "success",
          variant: "left-accent",
        });
        setUser(object);
      } else {
        Toast.show({
          title: response 
            ? response.data.error.message 
            : "Não foi possível avançar. Verifique sua conexão e tente novamente",
          duration: 1500,
          isClosable: true,
          placement: width <= 1024 ? "bottom" : "bottom-left",
          padding: 4,
          marginLeft: width <= 1024 ? 0 : 20,
          status: "error",
          variant: "left-accent",
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <View style={{ height: "100%" }}>
      <SafeAreaView style={{ backgroundColor: Colors.white, flex: 1 }}>
        {/* <View style={{ height: '100%' }}> */}
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}>
          <Container>
            <View style={{ alignSelf: "flex-end" }}>
              <Close onPress={() => props.navigation.goBack()} />
            </View>
            <LogoContainer
              style={{
                marginTop: height * 0.035,
                width: width < 768 ? "70%" : "40%",
              }}>
              <Logo width="100%" height="100%" />
            </LogoContainer>
            <InputContainer marginTop={height * 0.05 + "px"}>
              {userType === "professional" && (
                <WarningText>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque vitae auctor urna, vel lobortis orci.
                </WarningText>
              )}

              <TitleInput>Nome Completo</TitleInput>
              <Input
                keyboard="default"
                return="next"
                placeholder="Digite seu nome"
                value={name}
                onChangeText={text => setName(text)}
                onSubmit={() =>
                  emailInputRef.current && emailInputRef.current.focus()
                }
              />
              {nameError !== "" && <InputError text={nameError} />}
              <TitleInput>E-mail</TitleInput>
              <Input
                inputRef={emailInputRef}
                keyboard="email-address"
                return="next"
                placeholder="Digite seu e-mail"
                value={email}
                onChangeText={text => setEmail(text.trim())}
                onSubmit={() =>
                  passwordInputRef.current && passwordInputRef.current.focus()
                }
              />
              {emailError !== "" && <InputError text={emailError} />}
              <TitleInput>Senha</TitleInput>
              <Input
                inputRef={passwordInputRef}
                secureTextEntry={true}
                keyboard="default"
                return="next"
                placeholder="Digite seu senha"
                value={password}
                onChangeText={text => setPassword(text)}
                onSubmit={() =>
                  birthdateInputRef.current &&
                  // @ts-ignore
                  birthdateInputRef.current._inputElement.focus()
                }
              />
              {passwordError !== "" && <InputError text={passwordError} />}
              <TitleInput>Data de nascimento</TitleInput>
              <Input
                inputRef={birthdateInputRef}
                mask="datetime"
                keyboard="numeric"
                return="next"
                placeholder="dd/mm/aaaa"
                value={birthdate}
                onChangeText={text => setBirthdate(text)}
                onSubmit={() => {}}
                blurOnSubmit={true}
              />
              {birthdateError !== "" && <InputError text={birthdateError} />}
              <TitleInput>Gênero</TitleInput>
              <DropDownComponent
                defaultValue={user?.gender}
                items={[
                  {
                    label: "Masculino",
                    value: "male",
                  },
                  {
                    label: "Feminino",
                    value: "female",
                  },
                  {
                    label: "Outro",
                    value: "other",
                  },
                ]}
                zIndex={9999}
                onChangeItem={(item: { value: string }) =>
                  setGender(item.value)
                }
              />
              {genderError !== "" && <InputError text={genderError} />}
              <TitleInput>Escolaridade</TitleInput>
              <DropDownComponent
                defaultValue={user?.schooling}
                items={
                  userType === "professional"
                    ? [
                        {
                          label: "Graduação",
                          value: "Graduação",
                        },
                        {
                          label: "Especialização",
                          value: "Especialização",
                        },
                        {
                          label: "Mestrado",
                          value: "Mestrado",
                        },
                        {
                          label: "Doutorado",
                          value: "Doutorado",
                        },
                        {
                          label: "Pós-doutorado",
                          value: "Pós-doutorado",
                        },
                      ]
                    : [
                        {
                          label: "Sem escolaridade",
                          value: "Sem escolaridade",
                        },
                        {
                          label: "Fundamental I (Primário)  incompleto",
                          value: "Fundamental I (Primário)  incompleto",
                        },
                        {
                          label: "Fundamental I (Primário) completo",
                          value: "Fundamental I (Primário) completo",
                        },
                        {
                          label: "Fundamental II (Ginásio) incompleto",
                          value: "Fundamental II (Ginásio) incompleto",
                        },
                        {
                          label: "Fundamental II (Ginásio) completo",
                          value: "Fundamental II (Ginásio) completo",
                        },
                        {
                          label: "Magistério",
                          value: "Magistério",
                        },
                        {
                          label: "Médio (Colegial) incompleto",
                          value: "Médio (Colegial) incompleto",
                        },
                        {
                          label: "Médio (Colegial) completo",
                          value: "Médio (Colegial) completo",
                        },
                        {
                          label: "Superior incompleto",
                          value: "Superior incompleto",
                        },
                        {
                          label: "Superior completo",
                          value: "Superior completo",
                        },
                        {
                          label: "Especialização",
                          value: "Especialização",
                        },
                        {
                          label: "Mestrado",
                          value: "Mestrado",
                        },
                        {
                          label: "Doutorado",
                          value: "Doutorado",
                        },
                        {
                          label: "Pós-doutorado",
                          value: "Pós-doutorado",
                        },
                      ]
                }
                zIndex={9998}
                onChangeItem={(item: { value: string }) =>
                  setSchooling(item.value)
                }
              />
              {schoolingError !== "" && <InputError text={schoolingError} />}
              {userType === "caregiver" && (
                <>
                  <TitleInput>Você é</TitleInput>
                  <DropDownComponent
                    defaultValue={
                      user &&
                      ![
                        "Professor",
                        "Pedagogo",
                        "Assistente Social",
                        "Mãe",
                        "Pai",
                        "Avó",
                        "Avô",
                        "Tio",
                        "Tia",
                      ].includes(user.caregiver_type)
                        ? "Outro"
                        : user?.caregiver_type
                    }
                    items={[
                      {
                        label: "Profissional",
                        value: "profissional",
                        untouchable: true,
                        textStyle: {
                          fontWeight: "bold",
                        },
                      },
                      {
                        label: "Professor",
                        value: "Professor",
                      },
                      {
                        label: "Pedagogo",
                        value: "Pedagogo",
                      },
                      {
                        label: "Assistente Social",
                        value: "Assistente Social",
                      },
                      {
                        label: "Cuidador",
                        value: "cuidador",
                        untouchable: true,
                        textStyle: {
                          fontWeight: "bold",
                        },
                      },
                      {
                        label: "Mãe",
                        value: "Mãe",
                      },
                      {
                        label: "Pai",
                        value: "Pai",
                      },
                      {
                        label: "Avó",
                        value: "Avó",
                      },
                      {
                        label: "Avô",
                        value: "Avô",
                      },
                      {
                        label: "Tio",
                        value: "Tio",
                      },
                      {
                        label: "Tia",
                        value: "Tia",
                      },
                      {
                        label: "Outro",
                        value: "Outro",
                      },
                    ]}
                    zIndex={9997}
                    onChangeItem={(item: { value: string }) =>
                      setCaregiverType(item.value)
                    }
                  />
                  {user &&
                    ![
                      "Professor",
                      "Pedagogo",
                      "Assistente Social",
                      "Mãe",
                      "Pai",
                      "Avó",
                      "Avô",
                      "Tio",
                      "Tia",
                    ].includes(user.caregiver_type) && (
                      <View>
                        <TitleInput>Qual?</TitleInput>
                        <Input
                          keyboard="default"
                          return="next"
                          blurOnSubmit={true}
                          placeholder="Preencha este campo"
                          value={otherCaregiverType}
                          onChangeText={text => setOtherCaregiverType(text)}
                          onSubmit={() => {}}
                        />
                      </View>
                    )}
                  {caregiverTypeError !== "" && (
                    <InputError text={caregiverTypeError} />
                  )}
                </>
              )}
              {userType === "professional" && (
                <>
                  <TitleInput>Profissão</TitleInput>
                  <DropDownComponent
                    defaultValue={
                      user &&
                      ![
                        "Médico",
                        "Enfermeiro",
                        "Fisioterapeuta",
                        "Terapeuta Ocupacional",
                        "Fonoaudiólogo",
                        "Psicólogo",
                      ].includes(user.occupation)
                        ? "Outro"
                        : user?.occupation
                    }
                    items={[
                      {
                        label: "Médico",
                        value: "Médico",
                      },
                      {
                        label: "Enfermeiro",
                        value: "Enfermeiro",
                      },
                      {
                        label: "Fisioterapeuta",
                        value: "Fisioterapeuta",
                      },
                      {
                        label: "Terapeuta Ocupacional",
                        value: "Terapeuta Ocupacional",
                      },
                      {
                        label: "Fonoaudiólogo",
                        value: "Fonoaudiólogo",
                      },
                      {
                        label: "Psicólogo",
                        value: "Psicólogo",
                      },
                      {
                        label: "Outro",
                        value: "Outro",
                      },
                    ]}
                    zIndex={9996}
                    onChangeItem={(item: { value: string }) =>
                      setOccupation(item.value)
                    }
                  />
                  {user &&
                    ![
                      "Médico",
                      "Enfermeiro",
                      "Fisioterapeuta",
                      "Terapeuta Ocupacional",
                      "Fonoaudiólogo",
                      "Psicólogo",
                    ].includes(user.occupation) && (
                      <View>
                        <TitleInput>Qual?</TitleInput>
                        <Input
                          keyboard="default"
                          return="next"
                          blurOnSubmit={true}
                          placeholder="Digite sua profissão"
                          value={otherOccupation}
                          onChangeText={text => setOtherOccupation(text)}
                          onSubmit={() => {}}
                        />
                      </View>
                    )}
                  {occupationError !== "" && (
                    <InputError text={occupationError} />
                  )}
                </>
              )}
              {userType === "professional" && (
                <>
                  <TitleInput>
                    Possui formação em desenvolvimento infantil?
                  </TitleInput>
                  <DropDownComponent
                    defaultValue={user?.has_specific_training.toString()}
                    items={[
                      {
                        label: "Sim",
                        value: "true",
                      },
                      {
                        label: "Não",
                        value: "false",
                      },
                    ]}
                    zIndex={9995}
                    onChangeItem={(item: { value: string }) =>
                      setHasSpecificTraining(item.value)
                    }
                  />
                  {hasSpecificTrainingError !== "" && (
                    <InputError text={hasSpecificTrainingError} />
                  )}
                </>
              )}

              {(userType === "professional" ||
                caregiverType === "Professor" ||
                caregiverType === "Pedagogo" ||
                caregiverType === "Assistente Social" ||
                caregiverType === "Outro") && (
                <View>
                  <TitleInput>Local de atuação profissional</TitleInput>
                  <DropDownComponent
                    defaultValue={
                      user &&
                      ![
                        "Universidade pública",
                        "Universidade privada",
                        "Ambulatório/clínica serviço público",
                        "Consultório particular",
                        "Hospital publico",
                        "Hospital privado",
                        "Centro de educação infantil pública (Creche)",
                        "Centro de educação infantil particular (Creche)",
                        "Escola pública",
                        "Escola particular",
                      ].includes(user.occupation_location)
                        ? "Outro"
                        : user?.occupation_location
                    }
                    items={
                      userType === "professional"
                        ? [
                            {
                              label: "Universidade pública",
                              value: "Universidade pública",
                            },
                            {
                              label: "Universidade privada",
                              value: "Universidade privada",
                            },
                            {
                              label: "Ambulatório/clínica serviço público",
                              value: "Ambulatório/clínica serviço público",
                            },
                            {
                              label: "Consultório particular",
                              value: "Consultório particular",
                            },
                            {
                              label: "Hospital publico",
                              value: "Hospital publico",
                            },
                            {
                              label: "Hospital privado",
                              value: "Hospital privado",
                            },
                            {
                              label:
                                "Centro de educação infantil pública (Creche)",
                              value:
                                "Centro de educação infantil pública (Creche)",
                            },
                            {
                              label:
                                "Centro de educação infantil particular (Creche)",
                              value:
                                "Centro de educação infantil particular (Creche)",
                            },
                            {
                              label: "Escola pública",
                              value: "Escola pública",
                            },
                            {
                              label: "Escola particular",
                              value: "Escola particular",
                            },
                            {
                              label: "Outro",
                              value: "Outro",
                            },
                          ]
                        : [
                            {
                              label:
                                "Centro de educação infantil pública (Creche)",
                              value:
                                "Centro de educação infantil pública (Creche)",
                            },
                            {
                              label:
                                "Centro de educação infantil particular (Creche)",
                              value:
                                "Centro de educação infantil particular (Creche)",
                            },
                            {
                              label: "Escola pública",
                              value: "Escola pública",
                            },
                            {
                              label: "Escola particular",
                              value: "Escola particular",
                            },
                            {
                              label: "Outro",
                              value: "Outro",
                            },
                          ]
                    }
                    zIndex={9994}
                    onChangeItem={(item: { value: string }) =>
                      setOccupationLocation(item.value)
                    }
                  />
                  {user &&
                    ![
                      "Universidade pública",
                      "Universidade privada",
                      "Ambulatório/clínica serviço público",
                      "Consultório particular",
                      "Hospital publico",
                      "Hospital privado",
                      "Centro de educação infantil pública (Creche)",
                      "Centro de educação infantil particular (Creche)",
                      "Escola pública",
                      "Escola particular",
                    ].includes(user.occupation_location) && (
                      <View>
                        <TitleInput>Qual?</TitleInput>
                        <Input
                          keyboard="default"
                          return="next"
                          blurOnSubmit={true}
                          placeholder="Digite seu local de atuação"
                          value={otherOccupationLocation}
                          onChangeText={text =>
                            setOtherOccupationLocation(text)
                          }
                          onSubmit={() => {}}
                        />
                      </View>
                    )}
                  {occupationLocationError !== "" && (
                    <InputError text={occupationLocationError} />
                  )}
                </View>
              )}

              <TitleInput>Estado</TitleInput>
              <Picker
                renderSelectView={(
                  __: any,
                  selected: IModalListInDto,
                  showModal: () => void
                ) => (
                  <TouchableOpacity
                    onPress={showModal}
                    style={{ height: 48.7 }}>
                    <CustomSelect style={{ height: 48.7 }}>
                      <Text
                        style={{
                          fontFamily: "Muli_400Regular",
                          textAlign: "left",
                          color:
                            selected.Name || user?.state ? "#454E4B" : "#bbb",
                        }}>
                        {selected.Name
                          ? selected.Name
                          : user?.state
                          ? user?.state
                          : "Selecione uma opção"}
                      </Text>
                      <Icon
                        name="chevron-down"
                        size={20}
                        color={Colors.mediumGray}
                      />
                    </CustomSelect>
                  </TouchableOpacity>
                )}
                onClosed={() => {}}
                onEndReached={() => {}}
                items={dropdownStates}
                showAlphabeticalIndex={false}
                showToTopButton={false}
                searchPlaceholderText={"Pesquise seu estado..."}
                onSelected={handleState}
              />
              {stateError !== "" && <InputError text={stateError} />}
              <TitleInput>Cidade</TitleInput>
              <Picker
                renderSelectView={(
                  __: any,
                  selected: IModalListInDto,
                  showModal: () => void
                ) => (
                  <TouchableOpacity
                    onPress={showModal}
                    style={{ height: 48.7 }}>
                    <CustomSelect style={{ height: 48.7 }}>
                      <Text
                        style={{
                          fontFamily: "Muli_400Regular",
                          textAlign: "left",
                          color:
                            selected.Name || user?.city ? "#454E4B" : "#bbb",
                        }}>
                        {selected.Name
                          ? selected.Name
                          : user?.city
                          ? user?.city
                          : "Selecione uma opção"}
                      </Text>
                      <Icon
                        name="chevron-down"
                        size={20}
                        color={Colors.mediumGray}
                      />
                    </CustomSelect>
                  </TouchableOpacity>
                )}
                items={dropdownCities}
                removeClippedSubviews={true}
                showAlphabeticalIndex={false}
                showToTopButton={false}
                searchPlaceholderText={"Pesquise sua cidade..."}
                onSelected={(item: IModalListInDto) => {
                  setCity(item.Value.toString());
                  return item;
                }}
                onClosed={() => {}}
                onEndReached={() => {}}
              />
              {cityError !== "" && <InputError text={cityError} />}
            </InputContainer>

            <ButtonsContainer>
              <Button
                text="Atualizar"
                onPress={handleUpdate}
                loading={isLoading}
              />
              <View style={{ height: 15 }} />
            </ButtonsContainer>
          </Container>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
};

export default SignUp;
