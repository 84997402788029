import React, { useContext, useRef, useState } from "react";
import {
  Text,
  ScrollView,
  View,
  TouchableOpacity,
  TextInput,
  useWindowDimensions,
} from "react-native";

import { SafeAreaView } from "react-native-safe-area-context";
import { TextInputMask } from "react-native-masked-text";
import { IModalListInDto } from "react-native-picker-modal-view/dist/Interfaces";
import { Toast } from "native-base";
import Icon from "react-native-vector-icons/Feather";

import { AuthContext } from "../../contexts/AuthContext";

import States from "../../services/states";
import { findByStateName } from "../../services/cities";
import Colors from "../../utils/Colors";
import { User } from "../../database/Models";

import {
  Container,
  LogoContainer,
  InputContainer,
  WarningText,
  TitleInput,
  ButtonsContainer,
  CustomSelect,
  Picker,
  TermListContainer,
  TermContainer,
} from "./styles";

import Close from "../../components/Close";
import Logo from "../../components/Logo";
import Input from "../../components/Input";
import Button from "../../components/Button";
import InputError from "../../components/InputError";
import DropDownComponent from "../../components/DropDown";
import Checkbox from "../../components/Checkbox";

interface PickerProps {
  Name: string;
  Value: string;
  Code: string;
  Id: number;
}

interface SignUpProps {
  route: any;
  navigation: any;
}

const SignUp: React.FC<SignUpProps> = props => {
  const { signUp, isAuthLoading } = useContext(AuthContext);

  const { width, height } = useWindowDimensions();

  const emailInputRef: React.RefObject<TextInput> = useRef(null);
  const passwordInputRef: React.RefObject<TextInput> = useRef(null);
  const birthdateInputRef: React.RefObject<TextInputMask> = useRef(null);

  const dropdownStates = States.map(function (state) {
    return {
      Name: state.nome,
      Value: state.nome,
      Code: state.sigla,
      Id: state.id,
    };
  });
  const [dropdownCities, setDropdownCities] = useState<PickerProps[]>([]);

  // All user related fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [gender, setGender] = useState("");
  const [skinColor, setSkinColor] = useState("");
  const [schooling, setSchooling] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  // Error handling
  // Error fields for all the users
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [skinColorError, setSkinColorError] = useState("");
  const [schoolingError, setSchoolingError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");

  const [checkBox, setCheckBox] = useState([false, false]);

  const handleCheckBox = (index: number) => {
    const list = [...checkBox];

    list[index] = !list[index];

    setCheckBox(list);
  };

  const handleState = (item: IModalListInDto) => {
    setState(item.Value.toString());

    const cities = findByStateName(item.Value.toString());

    if (cities) {
      const newDropdownCities: PickerProps[] = cities.map(city => {
        return {
          Name: city.nome,
          Value: city.nome,
          Code: city.nome,
          Id: city.id,
        };
      });

      setDropdownCities(newDropdownCities);
    }
  };

  const handleError = () => {
    // Set and unset errors on user submit
    if (name.trim() === "") {
      setNameError("Preencha o campo nome");
      return true;
    }
    setNameError("");
    if (email === "") {
      setEmailError("Preencha o campo e-mail");
      return true;
    }
    setEmailError("");
    if (password === "" || password.length < 6) {
      setPasswordError("Digite uma senha de 6 digítos ou mais");
      return true;
    }
    setPasswordError("");
    if (birthdate.length < 10) {
      setBirthdateError("Digite uma data válida");
      return true;
    }
    setBirthdateError("");
    if (gender === "") {
      setGenderError("Selecione uma opção no campo gênero");
      return true;
    }
    setGenderError("");
    if (skinColor === "") {
      setSkinColorError("Selecione uma opção no campo cor ou raça");
      return true;
    }
    setSkinColorError("");
    if (schooling === "") {
      setSchoolingError("Selecione uma opção no campo escolaridade");
      return true;
    }
    setSchoolingError("");
    if (state === "") {
      setStateError("Selecione uma opção no campo estado");
      return true;
    }
    setStateError("");
    if (city === "") {
      setCityError("Selecione uma opção no campo cidade");
      return true;
    }
    setCityError("");
    // Checkbox validation
    if (!checkBox[0] || !checkBox[1]) {
      Toast.show({
        title: "Aceite os termos de uso",
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });
      return true;
    }

    return false;
  };

  const handleSignUp = async () => {
    if (handleError()) return;

    // Convert date to yyyy/mm/yy
    const birthdateParts = birthdate.split("/");

    const user: User = {
      name: name.trim(),
      email,
      password,
      gender,
      birth_date: new Date(
        +parseInt(birthdateParts[2]),
        parseInt(birthdateParts[1]) - 1,
        +parseInt(birthdateParts[0])
      ),
      skin_color: skinColor,
      schooling,
      state,
      city,
    };

    const response = await signUp(user);

    if (!response || response?.status != 201) {
      Toast.show({
        title: response
          ? response.data.error.message
          : "Não foi possível avançar. Verifique sua conexão e tente novamente",
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });
    }
  };

  return (
    <View style={{ height: "100%" }}>
      <SafeAreaView style={{ backgroundColor: Colors.white, flex: 1 }}>
        {/* <View style={{ height: '100%' }}> */}
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={true}>
          <Container>
            <View
              style={{
                width: width <= 1024 ? "90%" : "50%",
              }}>
              <Close onPress={() => props.navigation.goBack()} />
            </View>

            <LogoContainer
              style={{
                marginTop: height * 0.035,
                width: width < 768 ? "70%" : "40%",
              }}>
              <Logo width="100%" height="100%" />
            </LogoContainer>
            <View
              style={{
                flex: 1,
                width: width <= 1024 ? "90%" : "50%",
              }}>
              <InputContainer marginTop={height * 0.02 + "px"}>
                <WarningText>
                  A versão TEDI PRO é destinada a profissionais de nível superior da saúde. Esta versão é destinada a outros profissionais que lidam diretamente com as crianças.
                </WarningText>
                <TitleInput>Nome Completo</TitleInput>
                <Input
                  keyboard="default"
                  return="next"
                  placeholder="Digite seu nome"
                  value={name}
                  onChangeText={text => setName(text)}
                  onSubmit={() =>
                    emailInputRef.current && emailInputRef.current.focus()
                  }
                />
                {nameError !== "" && <InputError text={nameError} />}
                <TitleInput>E-mail</TitleInput>
                <Input
                  inputRef={emailInputRef}
                  keyboard="email-address"
                  return="next"
                  placeholder="Digite seu e-mail"
                  value={email}
                  onChangeText={text => setEmail(text.trim())}
                  onSubmit={() =>
                    passwordInputRef.current && passwordInputRef.current.focus()
                  }
                />
                {emailError !== "" && <InputError text={emailError} />}
                <TitleInput>Senha</TitleInput>
                <Input
                  inputRef={passwordInputRef}
                  secureTextEntry={true}
                  keyboard="default"
                  return="next"
                  placeholder="Digite seu senha"
                  value={password}
                  onChangeText={text => setPassword(text)}
                  onSubmit={() =>
                    birthdateInputRef.current &&
                    // @ts-ignore
                    birthdateInputRef.current._inputElement.focus()
                  }
                />
                {passwordError !== "" && <InputError text={passwordError} />}
                <TitleInput>Data de nascimento</TitleInput>
                <Input
                  inputRef={birthdateInputRef}
                  mask="datetime"
                  keyboard="numeric"
                  return="next"
                  placeholder="dd/mm/aaaa"
                  value={birthdate}
                  onChangeText={text => setBirthdate(text)}
                  onSubmit={() => { }}
                  blurOnSubmit={true}
                />
                {birthdateError !== "" && <InputError text={birthdateError} />}
                <TitleInput>Gênero</TitleInput>
                <DropDownComponent
                  items={[
                    { label: "Masculino", value: "male" },
                    { label: "Feminino", value: "female" },
                    { label: "Outro", value: "other" },
                  ]}
                  zIndex={9999}
                  onChangeItem={(item: { value: string }) =>
                    setGender(item.value)
                  }
                />
                {genderError !== "" && <InputError text={genderError} />}
                <TitleInput>Cor ou raça</TitleInput>
                <DropDownComponent
                  items={[
                    { label: "Branca", value: "branca" },
                    { label: "Preta", value: "preta" },
                    { label: "Amarela", value: "amarela" },
                    { label: "Parda", value: "parda" },
                    { label: "Indígena", value: "indigena" },
                    { label: "Sem declaração", value: "sem declaracao" },
                  ]}
                  zIndex={8000}
                  onChangeItem={(item: { value: string; }) =>
                    setSkinColor(item.value)
                  }
                />
                {skinColorError !== "" && <InputError text={skinColorError} />}
                <TitleInput>Escolaridade</TitleInput>
                <DropDownComponent
                  items={[
                    {
                      label: "Sem escolaridade",
                      value: "Sem escolaridade",
                    },
                    {
                      label: "Fundamental I (Primário)  incompleto",
                      value: "Fundamental I (Primário)  incompleto",
                    },
                    {
                      label: "Fundamental I (Primário) completo",
                      value: "Fundamental I (Primário) completo",
                    },
                    {
                      label: "Fundamental II (Ginásio) incompleto",
                      value: "Fundamental II (Ginásio) incompleto",
                    },
                    {
                      label: "Fundamental II (Ginásio) completo",
                      value: "Fundamental II (Ginásio) completo",
                    },
                    {
                      label: "Médio (Colegial) incompleto",
                      value: "Médio (Colegial) incompleto",
                    },
                    {
                      label: "Médio (Colegial) completo",
                      value: "Médio (Colegial) completo",
                    },
                    {
                      label: "Superior incompleto",
                      value: "Superior incompleto",
                    },
                    {
                      label: "Superior completo",
                      value: "Superior completo",
                    },
                    {
                      label: "Pós-graduação",
                      value: "Pós-graduação",
                    },
                  ]}
                  zIndex={9998}
                  onChangeItem={(item: { value: string; }) =>
                    setSchooling(item.value)
                  }
                />
                {schoolingError !== "" && <InputError text={schoolingError} />}
                <TitleInput>Estado</TitleInput>
                <Picker
                  renderSelectView={(
                    __: any,
                    selected: IModalListInDto,
                    showModal: () => void
                  ) => (
                    <TouchableOpacity
                      onPress={showModal}
                      style={{ height: 48.7 }}>
                      <CustomSelect style={{ height: 48.7 }}>
                        <Text
                          style={{
                            fontFamily: "Muli_400Regular",
                            textAlign: "left",
                            color: selected.Name ? "#454E4B" : "#bbb",
                          }}>
                          {selected.Name ?? "Selecione uma opção"}
                        </Text>
                        <Icon
                          name="chevron-down"
                          size={20}
                          color={Colors.mediumGray}
                        />
                      </CustomSelect>
                    </TouchableOpacity>
                  )}
                  onClosed={() => { }}
                  onEndReached={() => { }}
                  items={dropdownStates}
                  showAlphabeticalIndex={false}
                  showToTopButton={false}
                  searchPlaceholderText={"Pesquise seu estado..."}
                  onSelected={handleState}
                />
                {stateError !== "" && <InputError text={stateError} />}
                <TitleInput>Cidade</TitleInput>
                <Picker
                  renderSelectView={(
                    __: any,
                    selected: IModalListInDto,
                    showModal: () => void
                  ) => (
                    <TouchableOpacity
                      onPress={showModal}
                      style={{ height: 48.7 }}>
                      <CustomSelect style={{ height: 48.7 }}>
                        <Text
                          style={{
                            fontFamily: "Muli_400Regular",
                            textAlign: "left",
                            color: selected.Name ? "#454E4B" : "#bbb",
                          }}>
                          {" "}
                          {selected.Name ?? "Selecione uma opção"}{" "}
                        </Text>
                        <Icon
                          name="chevron-down"
                          size={20}
                          color={Colors.mediumGray}
                        />
                      </CustomSelect>
                    </TouchableOpacity>
                  )}
                  items={dropdownCities}
                  removeClippedSubviews={true}
                  showAlphabeticalIndex={false}
                  showToTopButton={false}
                  searchPlaceholderText={"Pesquise sua cidade..."}
                  onSelected={(item: IModalListInDto) => {
                    setCity(item.Value.toString());
                    return item;
                  }}
                  onClosed={() => { }}
                  onEndReached={() => { }}
                />
                {cityError !== "" && <InputError text={cityError} />}

                <TermListContainer>
                    <TouchableOpacity onPress={() => handleCheckBox(0)}>
                      <TermContainer>
                        <Checkbox
                          wrapperRadius="13px"
                          innerRadius="8px"
                          selected={checkBox[0]}
                        />
                        <TitleInput style={{ marginLeft: 15 }}>
                          Estou ciente que este aplicativo não tem por objetivo fornecer 
                          diagnóstico de alterações do desenvolvimento e do comportamento 
                          e que os resultados gerados por este aplicativo devem ser discutidos e 
                          avaliados pelos profissionais de saúde que acompanham a criança.
                        </TitleInput>
                      </TermContainer>
                    </TouchableOpacity>
                  <TouchableOpacity onPress={() => handleCheckBox(1)}>
                    <TermContainer>
                      <Checkbox
                        wrapperRadius="13px"
                        innerRadius="8px"
                        selected={checkBox[1]}
                      />
                      <TitleInput style={{ marginLeft: 15 }}>
                        Estou ciente de que os dados armazenados poderão ser
                        utilizados para fins de pesquisa científica, sem
                        qualquer identificação dos usuários e das crianças ou de
                        seus dados.
                      </TitleInput>
                    </TermContainer>
                  </TouchableOpacity>
                </TermListContainer>
              </InputContainer>
              <ButtonsContainer>
                <Button
                  text="Cadastrar"
                  loading={isAuthLoading}
                  onPress={handleSignUp}
                />
                <View style={{ height: 15 }} />
              </ButtonsContainer>
            </View>
          </Container>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
};

export default SignUp;
