import React, { useState, useContext, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  Modal,
  TouchableWithoutFeedback,
  useWindowDimensions,
} from "react-native";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Toast } from "native-base";
import { FontAwesome5 } from "@expo/vector-icons";

import { ChildrenContext } from "../../contexts/ChildrenContext";
import { FormContext } from "../../contexts/FormContext";

import { Form } from "../../database/Models";

import Colors, { cardColors } from "../../utils/Colors";
import { getMonthsDiff } from "../../utils/Functions";

import Button from "../../components/Button";
import ItemCard from "../../components/ItemCard";

import {
  Container,
  ChildCard,
  ChildAvatar,
  ChildContent,
  ChildName,
  ChildMonths,
  ChildOptions,
  ChildOptionsText,
  SurveyCard,
  SurveyContent,
  SurveyName,
  SurveyDate,
  SurveyOptions,
  Footer,
  ButtonsContainer,
  ModalButton,
  ModalButtonContainer,
  ModalContainer,
  ModalContent,
  ModalOverlay,
  ModalText,
  ModalTitle,
} from "./styles";

interface ChildDetailsProps {
  navigation: any;
}

const ChildDetails: React.FC<ChildDetailsProps> = props => {
  const { width } = useWindowDimensions();
  const insets = useSafeAreaInsets();

  const { selectedChild, childArray } = useContext(ChildrenContext);
  const { isFormLoading, setSelectedForm, createForm, deleteForm } = useContext(FormContext);

  const [selectedId, setSelectedId] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDeleteForm = async (form: Form | null) => {
    //   Set child to be deleted
    if (form && form.id) setSelectedId(form.id);
    setIsModalVisible(!isModalVisible);

    if (isModalVisible && selectedId !== 0) {
      const response = await deleteForm(selectedId);

      if (!response || response?.status != 200) {
        Toast.show({
          title: response
            ? response.data.error.message
            : "Não foi possível avançar. Verifique sua conexão e tente novamente",
          duration: 1500,
          isClosable: true,
          placement: width <= 1024 ? "bottom" : "bottom-left",
          padding: 4,
          marginLeft: width <= 1024 ? 0 : 20,
          status: "error",
          variant: "left-accent",
        });
      }
    }
  };

  const handleCreateForm = async () => {
    if (isFormLoading) return;

    const childCorrectedAge = getMonthsDiff(
      selectedChild.birth_date,
      selectedChild.gestational_age
    );

    if (selectedChild && (childCorrectedAge < 1 || childCorrectedAge > 65)) {
      Toast.show({
        title: "Não existe um formulário para essa idade",
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });

      return;
    }

    let canCreate: boolean = true;
    selectedChild.forms?.every(form => {
      if (childCorrectedAge === form.months) {
        canCreate = false;
        return false;
      }

      return true;
    });

    if (!canCreate) {
      Toast.show({
        title: "Já existe um formulário para essa idade",
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });

      return;
    }

    const monthsChronological = getMonthsDiff(
      selectedChild.birth_date
    );

    const newForm: Form = {
      months: childCorrectedAge,
      months_corrected: childCorrectedAge,
      months_chronological: monthsChronological,
      date: new Date(),
      child_id: selectedChild.id,
    };

    const response = await createForm(newForm);

    if (response && response.status === 201) {
      setSelectedForm(response.data.form);

      props.navigation.navigate("FormMain");
    } else {
      Toast.show({
        title: "Ocorreu em erro, tente novamente mais tarde.",
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });
    }
  };

  return (
    <View
      style={{
        flex: 1,
        paddingBottom: insets.bottom,
        backgroundColor: Colors.background,
      }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Container>
          <ChildCard>
            <ChildAvatar
              backgroundColor={
                cardColors[childArray.indexOf(selectedChild) % 4 ?? 0]
              }>
              <FontAwesome5 name="user-alt" size={80} color={Colors.white} />
            </ChildAvatar>
            <ChildContent>
              <ChildName>
                <Text>{selectedChild?.name}</Text>
              </ChildName>
              <ChildMonths>
                <Text>{getMonthsDiff(selectedChild.birth_date)} Meses</Text>
              </ChildMonths>
              {selectedChild.gestational_age < 37 &&
                getMonthsDiff(selectedChild.birth_date) < 24 && (
                  <ChildMonths>
                    <Text>
                      Idade corrigida:{" "}
                      {getMonthsDiff(
                        selectedChild.birth_date,
                        selectedChild.gestational_age
                      )}{" "}
                      meses
                    </Text>
                  </ChildMonths>
                )}
            </ChildContent>
            <ChildOptions
              onPress={() => props.navigation.navigate("UpdateChild")}>
              <FontAwesome5 name="edit" size={16} color={Colors.darkGray} />
              <ChildOptionsText>Editar</ChildOptionsText>
            </ChildOptions>
          </ChildCard>
          {selectedChild &&
            selectedChild.forms?.map(form => (
              <ItemCard
                key={form.id}
                item={form}
                onPress={() => {
                  setSelectedForm(form);
                  props.navigation.navigate("FormMain");
                }}
                onDelete={() => handleDeleteForm(form)}
              />
            ))}
        </Container>
      </ScrollView>
      <ButtonsContainer>
        <Button text="Responder formulário" onPress={handleCreateForm} />
      </ButtonsContainer>
      <ModalContainer>
        <Modal
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          statusBarTranslucent={true}
          onRequestClose={() => {
            setIsModalVisible(!isModalVisible);
          }}>
          <TouchableWithoutFeedback
            onPress={() => {
              setIsModalVisible(!isModalVisible);
            }}>
            <ModalOverlay>
              {/* <View style={styles.alert}> */}
              <ModalContent>
                <ModalTitle>Tem certeza?</ModalTitle>

                <ModalText>
                  Excluir esse formulário é uma ação irreversível.
                </ModalText>
                <ModalButtonContainer>
                  <ModalButton style={{ marginRight: 5, flexBasis: 'auto' }}>
                    <Button
                      text="Excluir"
                      backgroundColor="#EB1A20"
                      underlayColor="#EB1A20"
                      onPress={() => {
                        handleDeleteForm(null);
                      }}
                    />
                  </ModalButton>
                  <ModalButton style={{ marginLeft: 5, flexBasis: 'auto' }}>
                    <Button
                      text="Cancelar"
                      onPress={() => setIsModalVisible(false)}
                    />
                  </ModalButton>
                </ModalButtonContainer>
              </ModalContent>
              {/* </View> */}
            </ModalOverlay>
          </TouchableWithoutFeedback>
        </Modal>
      </ModalContainer>
    </View>
  );
};

export default ChildDetails;
