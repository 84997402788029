import React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import OnBoarding from "../pages/OnBoarding";
import Login from "../pages/Login";
import SignUp from "../pages/Sign_Up";
import ForgotPassword from "../pages/Forgot_Password";

const AuthStack = createStackNavigator();

interface AuthRoutesProps {
  initialRoute: string;
}

const AuthRoutes: React.FC<AuthRoutesProps> = props => {
  return (
    <AuthStack.Navigator>
      {props.initialRoute === "onboarding" || true ? (
        <AuthStack.Screen
          name="onboarding"
          component={OnBoarding}
          options={{ title: "Bem-vindo(a)", headerShown: false }}
        />
      ) : null}
      <AuthStack.Screen
        name="login"
        component={Login}
        options={{ title: "Login", headerShown: false }}
      />
      <AuthStack.Screen
        name="signup"
        component={SignUp}
        options={{ title: "Cadastro", headerShown: false }}
      />
      <AuthStack.Screen
        name="forgot_password"
        component={ForgotPassword}
        options={{ title: "Esqueci minha senha", headerShown: false }}
      />
    </AuthStack.Navigator>
  );
};

export default AuthRoutes;
