import { TextStyle, StyleSheet } from "react-native";

import Colors from "../../utils/Colors";

export const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    borderColor: Colors.mediumGray,
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: Colors.white,
  },
  input: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    height: 45,
    paddingLeft: 20,
    fontFamily: "Muli_400Regular",
    fontSize: 14,
    color: Colors.black,
  } as TextStyle,
  eyeButton: {
    justifyContent: "center",
    position: "absolute",
    right: 10,
    backgroundColor: Colors.white,
  },
});
