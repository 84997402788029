import axios from 'axios';

import { expo } from "../../app.json";

/* Endereços para uso/teste:
** https://api-cuidadores.tedi.medicina.ufmg.br
** http://192.168.0.115:3000
*/

export const baseURL = "https://api-cuidadores.tedi.medicina.ufmg.br";

const api = axios.create({
    baseURL,
});

api.defaults.headers['app_version'] = expo.version;

export default api;
