import React from 'react';
import { View, Text } from 'react-native';

import { Container, Message } from './styles';

interface InputErrorProps {
    text: string;
}

const InputError: React.FC<InputErrorProps> = (props) => {

    return (
        <Container>
            <Message>{props.text}</Message>
        </Container>
    );
};

export default InputError;
