import React from "react";
import { TouchableOpacity } from "react-native";

import Colors from "../../utils/Colors";
import { Container, CloseButton } from "./styles";

interface CloseProps {
  onPress: any;
  size?: number;
  color?: string;
}

const Close: React.FC<CloseProps> = props => {
  return (
    <Container>
      <TouchableOpacity onPress={props.onPress}>
        <CloseButton
          name="close"
          color={props.color ?? Colors.mediumGray}
          size={props.size ?? 30}
          // iconStyle={{ marginRight: 0 }}
        />
      </TouchableOpacity>
    </Container>
  );
};

export default Close;
