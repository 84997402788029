import styled from "styled-components/native";
import Colors from "../../utils/Colors";

interface CardProps {
  paddingLeft: string;
}

export const Card = styled.TouchableOpacity<CardProps>`
  flex-direction: row;
  height: 90px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  padding-left: ${(props: CardProps) => props.paddingLeft};
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.1)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
  border-radius: 5px;
`;

interface AvatarProps {
  backgroundColor: string;
}

export const Avatar = styled.View<AvatarProps>`
  display: flex;
  height: 50px;
  width: 50px;
  padding-top: 10px;
  border-radius: 25px;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  shadow-color: "rgba(138, 149, 158, 0.15)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
`;

export const Content = styled.View`
  margin: 0 20px;
  flex: 1;
`;

export const Title = styled.Text`
  font-family: Muli_700Bold;
  font-size: 20px;
  color: ${Colors.black};
`;

export const Subtitle = styled.Text`
  font-family: Muli_700Bold;
  font-size: 14px;
  color: ${Colors.darkGray};
`;
