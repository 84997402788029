import React, { useContext, useEffect, useState } from "react";
import { Text, ScrollView, View } from "react-native";

import * as Linking from "expo-linking";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { FontAwesome5, Ionicons, MaterialIcons } from "@expo/vector-icons";

import { expo } from "../../../app.json";

import { AuthContext } from "../../contexts/AuthContext";
import { ChildrenContext } from "../../contexts/ChildrenContext";

import Colors, { cardColors } from "../../utils/Colors";

import {
  Container,
  UserCard,
  UserAvatar,
  UserContent,
  UserName,
  UserAvaliations,
  UserOptions,
  UserOptionsText,
  Button,
  ButtonText,
  AppVersion,
  AppVersionText,
  AppEmail,
} from "./styles";

interface SettingsProps {
  navigation: any;
}

const Settings: React.FC<SettingsProps> = props => {
  const insets = useSafeAreaInsets();

  const { user, signOut } = useContext(AuthContext);
  const { childArray } = useContext(ChildrenContext);

  const [avaliations, setAvaliations] = useState("");

  useEffect(() => {
    let counter = 0;
    childArray.forEach(child => {
      if (child.forms) counter += child.forms.length;
    });

    if (counter === 0) setAvaliations("Nenhuma avaliação feita");
    else if (counter === 1) setAvaliations("1 avaliação");
    else setAvaliations(counter + " avaliações");
  }, []);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Colors.background,
        paddingTop: insets.top,
      }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Container>
          <UserCard>
            <UserAvatar backgroundColor={cardColors[2]}>
              <FontAwesome5 name="user-alt" size={80} color={Colors.white} />
            </UserAvatar>
            <UserContent>
              <UserName>{user?.name}</UserName>
              <UserAvaliations>
                <Text>{avaliations}</Text>
              </UserAvaliations>
            </UserContent>
            <UserOptions
              onPress={() => props.navigation.navigate("UpdateUser")}>
              <FontAwesome5 name="edit" size={16} color={Colors.darkGray} />
              <UserOptionsText>Editar</UserOptionsText>
            </UserOptions>
          </UserCard>
          <Button onPress={() => props.navigation.navigate("Videos")}>
            <Ionicons name="videocam" size={24} color={Colors.darkGray} />
            <ButtonText>Videos</ButtonText>
          </Button>
          <Button onPress={() => props.navigation.navigate("Tutorial")}>
            <Ionicons name="school" size={24} color={Colors.darkGray} />
            <ButtonText>Tutorial</ButtonText>
          </Button>
          <Button
            onPress={() =>
              Linking.openURL(
                "https://tedi.medicina.ufmg.br/wp-content/uploads/documentos/Manual-do-SWYC-Br.pdf"
              )
            }>
            <Ionicons name="file-tray" size={24} color={Colors.darkGray} />
            <ButtonText>Manual do SWYC</ButtonText>
          </Button>
          <Button onPress={() => props.navigation.navigate("Credits")}>
            <Ionicons name="bookmark" size={24} color={Colors.darkGray} />
            <ButtonText>Créditos</ButtonText>
          </Button>
          <AppVersion>
            <AppEmail
              onPress={() => Linking.openURL("mailto:tedi@medicina.ufmg.br")}>
              tedi@medicina.ufmg.br
            </AppEmail>
          </AppVersion>
          <Button onPress={signOut}>
            <MaterialIcons
              name="exit-to-app"
              size={24}
              color={Colors.darkGray}
            />
            <ButtonText>Sair</ButtonText>
          </Button>
          <AppVersion>
            <AppVersionText>
              version: {expo.version} | ios: {expo.ios.buildNumber} | andr:{" "}
              {expo.android.versionCode}
            </AppVersionText>
          </AppVersion>
        </Container>
      </ScrollView>
    </View>
  );
};

export default Settings;
