import * as React from "react";
import { Image } from "react-native";

interface Props {
  width: number;
  height: number;
}

const LogoNoText = ({ width, height }: Props) => {
  return (
    <Image
      style={{ width: width, height: height }}
      source={require("../../../assets/logo_no_text.png")}
    />
  );
};

export default LogoNoText;
