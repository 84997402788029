import React from "react";
import {
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";

import { Ionicons, Feather } from "@expo/vector-icons";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { ChildrenProvider } from "../contexts/ChildrenContext";
import { FormProvider } from "../contexts/FormContext";

import Colors from "../utils/Colors";

import Close from "../components/Close";

import Home from "../pages/Home";
import AddChild from "../pages/AddChild";
import UpdateChild from "../pages/UpdateChild";
import Videos from "../pages/Videos";
import Tutorial from "../pages/Tutorial";
import Credits from "../pages/Credits";
import UpdateUser from "../pages/UpdateUser";
import Settings from "../pages/Settings";
import ChildDetails from "../pages/ChildDetails";

import SwycMain from "../pages/SwycMain";
import Questionary from "../pages/Questionary";
import DecisionGuide from "../pages/DecisionGuide";

const Tab = createBottomTabNavigator();
const HomeStack = createStackNavigator();
const FormStack = createStackNavigator();

const AppRoutes: React.FC = () => {
  const { width } = useWindowDimensions();

  const TabsStackScreen = () => {
    return (
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused, color, size }) => {
            let iconName: React.ComponentProps<typeof Ionicons>["name"];

            iconName = focused ? "home" : "home-outline";

            if (route.name === "Opcoes") {
              iconName = focused ? "settings-sharp" : "settings-outline";
            }

            // You can return any component that you like here!
            return <Ionicons name={iconName} size={size} color={color} />;
          },
          tabBarActiveTintColor: "Colors.main",
          tabBarInactiveTintColor: Colors.darkGray,
          tabBarLabelStyle: { fontFamily: "Muli_700Bold", fontSize: 12 },
        })}>
        <Tab.Screen
          name="Home"
          component={Home}
          options={{ title: "Inicio" }}
        />
        <Tab.Screen
          name="Opcoes"
          component={Settings}
          options={{ title: "Opções" }}
        />
      </Tab.Navigator>
    );
  };

  const FormStackScreen = () => {
    return (
      <FormStack.Navigator
        screenOptions={({ navigation, route }) => ({
          headerTintColor: Colors.white,
          headerStyle: {
            backgroundColor: Colors.main,
          },
          headerTitleAlign: "left",
          headerLeftContainerStyle: {
            justifyContent: "center",
            alignItems: "flex-start",
            marginLeft: 15,
          },
          headerRightContainerStyle: {
            paddingHorizontal: 20,
          },
          headerTitleContainerStyle: {
            left:
              !navigation.canGoBack ||
                route.name === "ChildDetails" ||
                route.name === "FormStep1" ||
                route.name === "FormMain" ||
                route.name === "FormQuestionary"
                ? 20
                : 50,
          },
          headerTitle: ({ style, children: title }) => {
            return (
              <View style={{ width: "100%" }}>
                <Text
                  numberOfLines={2}
                  style={{
                    fontSize: 18,
                    fontFamily: "Roboto_500Medium",
                    color: Colors.white,
                    textAlign: "left",
                  }}>
                  {title}
                </Text>
              </View>
            );
          },
          headerLeft: props => {
            if (
              !navigation.canGoBack ||
              route.name === "ChildDetails" ||
              route.name === "FormStep1" ||
              route.name === "FormMain" ||
              route.name === "FormQuestionary"
            ) {
              return null;
            }

            return (
              <TouchableOpacity {...props}>
                <Feather name="chevron-left" size={24} color={Colors.white} />
              </TouchableOpacity>
            );
          },
          headerRight: () => {
            let returnFunction: () => any;
            let hasHomeButton = false;
            switch (route.name) {
              case "ChildDetails":
                returnFunction = () => navigation.goBack();
                break;
              case "FormQuestionary":
              case "DecisionGuide":
                hasHomeButton = true;
                returnFunction = () => navigation.navigate("FormMain");
                break;
              default:
                hasHomeButton = true;
                returnFunction = () => navigation.navigate("ChildDetails");
                break;
            }

            if (hasHomeButton) {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                  }}>
                  <TouchableOpacity
                    onPress={() => navigation.navigate("TabsStack")}>
                    <Ionicons name="home" size={22} color={Colors.white} />
                  </TouchableOpacity>
                  <View style={{ width: 12 }} />
                  <Close
                    size={24}
                    onPress={returnFunction}
                    color={Colors.white}
                  />
                </View>
              );
            }

            return (
              <Close
                size={24}
                onPress={returnFunction}
                color={Colors.white}
              />
            );
          },
        })}>
        <FormStack.Screen
          name="ChildDetails"
          component={ChildDetails}
          options={{ title: "Ficha da criança" }}
        />
        <FormStack.Screen
          name="FormMain"
          component={SwycMain}
          options={{ title: "SWYC" }}
        />
        <FormStack.Screen
          name="FormQuestionary"
          component={Questionary}
          options={{ title: "Questionário" }}
        />
        <FormStack.Screen
          name="DecisionGuide"
          component={DecisionGuide}
          options={{ title: "Guia para tomada de decisões" }}
        />
      </FormStack.Navigator>
    );
  };

  return (
    <ChildrenProvider>
      <FormProvider>
        <HomeStack.Navigator
          screenOptions={({ navigation, route }) => ({
            cardStyle: {
              alignSelf: "center",
              width: width <= 1024 ? "100%" : "50%",
            },
            headerTintColor: Colors.white,
            headerStyle: {
              backgroundColor: Colors.main,
            },
            headerRightContainerStyle: {
              paddingHorizontal: 20,
            },
            headerTitle: ({ style, children: title }) => {
              return (
                <Text
                  numberOfLines={2}
                  style={{
                    fontSize: 20,
                    fontFamily: "Roboto_500Medium",
                    color: Colors.white,
                  }}>
                  {title}
                </Text>
              );
            },
            headerLeft: () => null,
            headerRight: () => {
              if (navigation.canGoBack) {
                return (
                  <Close
                    onPress={() => navigation.goBack()}
                    color={Colors.white}
                  />
                );
              }

              return null;
            },
          })}>
          <HomeStack.Screen
            name="TabsStack"
            component={TabsStackScreen}
            options={{ headerShown: false }}
          />
          <HomeStack.Screen
            name="AddChild"
            component={AddChild}
            options={{ title: "Cadastro de criança" }}
          />
          <HomeStack.Screen
          name="UpdateChild"
          component={UpdateChild}
          options={{ title: "Editar criança" }}
        />
          <HomeStack.Screen
            name="UpdateUser"
            component={UpdateUser}
            options={{ headerShown: false, title: "Atualizar conta" }}
          />
          <HomeStack.Screen
            name="Videos"
            component={Videos}
            options={{ title: "Vídeos de estímulo" }}
          />
          <HomeStack.Screen
            name="Tutorial"
            component={Tutorial}
            options={{ headerShown: false, title: "Tutorial" }}
          />
          <HomeStack.Screen
            name="Credits"
            component={Credits}
            options={{ headerShown: false, title: "Créditos" }}
          />
          <HomeStack.Screen
            name="FormStack"
            component={FormStackScreen}
            options={{ headerShown: false }}
          />
        </HomeStack.Navigator>
      </FormProvider>
    </ChildrenProvider>
  );
};

export default AppRoutes;
